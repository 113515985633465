import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';

class Thanks extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false,
        };
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
      document.getElementById('last').style.display = 'none';
        this.setState({ show: true });
    }

    render(){
        return (
        <>
        <Button variant="success" onClick={this.handleShow}>Complete Order</Button>
        <Modal size="xl" show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>THANK YOU!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container-fluid contentbox">
                <h3>Your Order is Completed!</h3>
                <p>We look forward to putting you on top of the search engines and pushing your social media stats above and beyond your competitors! Are you ready to get more views, make more sales, and make more profits than ever before?</p>
              </div>
            </Modal.Body>
          <Modal.Footer><Button variant="secondary" onClick={this.handleClose}>Close</Button></Modal.Footer>
        </Modal>
        </>
          );
      }
  }

export default Thanks;
