import React, {Component} from 'react';
import {Button, Modal, Form, Alert, Nav} from 'react-bootstrap';
import OrderModal from './OrderModal';

class PayModalLink extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleCardtype = this.handleCardtype.bind(this);
        this.handleConsult = this.handleConsult.bind(this);
        this.handleCode = this.handleCode.bind(this);
        this.handleCardname = this.handleCardname.bind(this);
        this.handleCardnum = this.handleCardnum.bind(this);
        this.handleExpire = this.handleExpire.bind(this);
        this.handleCVV = this.handleCVV.bind(this);
        this.handleCardAddress = this.handleCardAddress.bind(this);
        this.handleCardCity = this.handleCardCity.bind(this);
        this.handleCardState = this.handleCardState.bind(this);
        this.handleCardZip = this.handleCardZip.bind(this);
        this.handleCap = this.handleCap.bind(this);
        this.handleBankName = this.handleBankName.bind(this);
        this.handleCheckName = this.handleCheckName.bind(this);
        this.handleCheckBank = this.handleCheckBank.bind(this);
        this.handleCheckNum = this.handleCheckNum.bind(this);
        this.handlePackage = this.handlePackage.bind(this);
        this.handleSpecial = this.handleSpecial.bind(this);
        this.handleRetail = this.handleRetail.bind(this);
        this.handleDiscount = this.handleDiscount.bind(this);
        this.handleMethod = this.handleMethod.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDcode = this.handleDcode.bind(this);

        this.state = {
          show: false,
          consultant: '',
          code: '',
          cardtype: '',
          cardname: '',
          cardnum: '',
          expire: '',
          cvv: '',
          cardaddress: '',
          cardcity: '',
          cardstate: '',
          cardzip: '',
          dailycap: '',
          bankname: '',
          checkname: '',
          checknum: '',
          checkbank: '',
          package: '',
          paymethod: '',
          special: '',
          retailprice: '',
          discountprice: '',
          incode: ''
        };
      }

        handlePackage(event) {
          this.setState({package: event.target.value});
        }

        handleSpecial(event) {
          this.setState({special: event.target.value});
        }

        handleRetail(event) {
          this.setState({retailprice: event.target.value});
        }

        handleDiscount(event) {
          this.setState({discountprice: event.target.value});
        }

        handleMethod(event) {
          this.setState({paymethod: event.target.value});
        }

        handleCardtype(event) {
          this.setState({cardtype: event.target.value});
        }

        handleCardname(event) {
          this.setState({cardname: event.target.value});
        }

        handleCardnum(event) {
          this.setState({cardnum: event.target.value});
        }

        handleExpire(event) {
          this.setState({expire: event.target.value});
        }

        handleCVV(event) {
          this.setState({cvv: event.target.value});
        }

        handleCardAddress(event) {
          this.setState({cardaddress: event.target.value});
        }

        handleCardCity(event) {
          this.setState({cardcity: event.target.value});
        }

        handleCardState(event) {
          this.setState({cardstate: event.target.value});
        }

        handleCardZip(event) {
          this.setState({cardzip: event.target.value});
        }

        handleCap(event){
          this.setState({dailycap: event.target.value});
        }

        handleBankName(event){
          this.setState({bankname: event.target.value});
        }

        handleCheckNum(event){
          this.setState({checknum: event.target.value});
        }

        handleCheckName(event){
          this.setState({checkname: event.target.value});
        }

        handleCheckBank(event){
          this.setState({checkbank: event.target.value});
        }

        handleClose() {
          this.setState({ show: false });
        }

        handleShow() {
          this.setState({ show: true });
        }

        handleConsult(event) {
          this.setState({consultant: event.target.value});
        }

        handleCode(event) {
          this.setState({code: event.target.value});
        }

        handleDcode(e){
          this.setState({incode: e.target.value});
        }

        render(){
          return (
          <>
          <Nav.Link onClick={this.handleShow}>Order</Nav.Link>
          <Modal show={this.state.show} onHide={this.handleClose} size="xl" id="layer6" backdrop={false}>
            <Modal.Header closeButton>
                <Modal.Title>Become Found Online!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="dcode">
                  <Form.Label>Input Your Demo Code</Form.Label>
                  <Form.Control type="text" onChange={this.handleDcode} />
                </Form.Group>
              </Form>
              {this.state.incode === this.props.dCode &&
              <>
              <Form>
              <Form>
                <Form.Group controlId="formConsultant">
                  {this.props.self === "guide" &&
                  <>
                  <Form.Label>Consultant</Form.Label>
                  <Form.Control type="text" onChange={this.handleConsult} value={this.state.consultant} />
                  </>
                  }
                </Form.Group>
                <Form.Group controlId="formOrderCode">
                  <Form.Label>Promo Code</Form.Label>
                  <Form.Control type="text" onChange={this.handleCode} value={this.state.code} />
                </Form.Group>
                <Form.Group controlId="formPackage" id="package">
                  <Form.Label>Package Type</Form.Label>
                  <Form.Control as="select" value={this.state.package} onChange={this.handlePackage}>
                    <option>Select One...</option>
                    {this.state.code === 'starter' &&
                    <option>Starter</option>
                    }
                    {this.state.code === 'upgrade' &&
                    <option>Upgrade</option>
                    }
                    {this.state.code === 'renewal' &&
                    <option>Renewal</option>
                    }
                    <option>Standard</option>
                    <option>Business Builder</option>
                    <option>Elite</option>
                  </Form.Control>
                  {this.state.package === 'Starter' &&
                    <>
                    <ul>
                      <li>Virtual Platform</li>
                      <li>2 Phrases with Guaranteed 1st Page Integration</li>
                      <li>Sales Funnel Design</li>
                      <li>Conversion Consulting</li>
                      <li>Social Media Integration</li>
                    </ul>
                    </>
                  }
                  {this.state.package === 'Standard' &&
                    <>
                    <ul>
                      <li>Virtual Platform</li>
                      <li>4 Phrases with Guaranteed 1st Page Integration</li>
                      <li>Sales Funnel Design</li>
                      <li>Social Media Integration</li>
                      <li>QR Code Integration</li>
                      <li>Conversion Consulting</li>
                    </ul>
                    </>
                  }
                  {this.state.package === 'Business Builder' &&
                    <>
                    <ul>
                      <li>Virtual Platform</li>
                      <li>8 Phrases with Guaranteed 1st Page Integration</li>
                      <li>Sales Funnel Design</li>
                      <li>Social Media Integration</li>
                      <li>QR Code Integration</li>
                      <li>Conversion Consulting</li>
                    </ul>
                    </>
                  }
                  {this.state.package === 'Elite' &&
                    <>
                    <ul>
                      <li>Custom Website with Built-In Virtual Platform</li>
                      <li>16 Phrases with Guaranteed 1st Page Integration</li>
                      <li>Sales Funnel Design</li>
                      <li>Social Media Integration</li>
                      <li>YouTube Integration</li>
                      <li>QR Code Integration</li>
                      <li>Conversion Consulting</li>
                    </ul>
                    </>
                  }
                  {this.props.self === "guide" &&
                  <>
                  {this.state.code === 'sbpre2024' &&
                  <>
                    <p>First Page Placement is guaranteed for six months, or the second six months are free.</p>
                    <p>If the guarantee is not met after twelve months, your initial investment will be fully returned.</p>
                  </>
                  }
                  <Form.Label>Notes</Form.Label>
                  <Form.Control as="textarea" rows="5" maxLength="100" value={this.state.special} onChange={this.handleSpecial}/>
                  </>
                  }
                </Form.Group>
                <Form.Group controlId="formPriceInfo" id="pricing">
                  <Form.Label>Retail Campaign Price</Form.Label>
                  {this.state.package === 'Starter' && this.state.consultant.length > 0 && this.state.code.length > 0 && this.state.special.length > 0 &&
                    <Form.Control type="text" value="$3,975" onChange={this.handleRetail} />
                  }
                  {this.state.package === 'Standard' && this.state.consultant.length > 0 && this.state.code.length > 0 && this.state.special.length > 0 &&
                    <Form.Control type="text" value="$5,895" onChange={this.handleRetail} />
                  }
                  {this.state.package === 'Business Builder' && this.state.consultant.length > 0 && this.state.code.length > 0 && this.state.special.length > 0 &&
                    <Form.Control type="text" value="$7,895" onChange={this.handleRetail} />
                  }
                  {this.state.package === 'Elite' && this.state.consultant.length > 0 && this.state.code.length > 0 && this.state.special.length > 0 &&
                    <Form.Control type="text" value="$10,895" onChange={this.handleRetail} />
                  }
                  <hr />
                  {this.props.self === "guide" &&
                  <>
                  <Form.Label>Discounted Campaign Price</Form.Label>
                  <Form.Control type="text" value={this.state.discountprice} onChange={this.handleDiscount} />
                  </>
                  }
                  <Form.Label>Method</Form.Label>
                  <Form.Control as="select" value={this.state.paymethod} onChange={this.handleMethod}>
                    <option>Choose a Method:</option>
                    <option>Debit</option>
                    <option>Credit</option>
                    <option>Amex</option>
                    <option>Check</option>
                  </Form.Control>
                </Form.Group>
                {this.state.paymethod !== "Check" &&
                  <Form.Group controlId="formCard" id="payform">
                    <Form.Label>Card Type</Form.Label>
                    <Form.Control as="select" value={this.state.cardtype} onChange={this.handleCardtype}>
                      <option>Select a Card Type</option>
                      <option>Visa</option>
                      <option>Mastercard</option>
                      {this.state.paymethod === "Credit" &&
                        <option>Discover</option>
                      }
                      {this.state.paymethod === "Amex" &&
                        <option>American Express</option>
                      }
                    </Form.Control>
                    <Form.Label>Cardholder Name</Form.Label>
                    <Form.Control type="text" value={this.state.cardname} onChange={this.handleCardname} />
                    <Form.Label>Card Number</Form.Label>
                    <Form.Control type="text" value={this.state.cardnum} onChange={this.handleCardnum} />
                    {this.state.cardnum.length !== 16 && this.state.cardnum.length !== 0  && this.state.paymethod !== "Amex" &&
                      <Form.Text className="text-muted" id="emailAlert">
                        Card Number Incorrect!
                      </Form.Text>
                    }
                    {this.state.paymethod === "Amex" && this.state.cardnum.length !== 0 && this.state.cardnum.length !== 15 &&
                      <Form.Text className="text-muted" id="emailAlert">
                        Card Number Incorrect!
                      </Form.Text>
                    }
                    <Form.Label>Expiration Date (MM/YY)</Form.Label>
                    <Form.Control type="text" value={this.state.expire} onChange={this.handleExpire} />
                    {this.state.expire.length !== 5 && this.state.expire.length !== 0 &&
                      <Form.Text className="text-muted" id="emailAlert">
                        Check Expiration Date!
                      </Form.Text>
                    }
                    <Form.Label>CVV Number</Form.Label>
                    <Form.Control type="text" value={this.state.cvv} onChange={this.handleCVV} />
                    {this.state.cvv.length !== 3 && this.state.cvv.length !== 0 && this.state.cardtype !== "American Express" &&
                      <Form.Text className="text-muted" id="emailAlert">
                        Check CVV!
                      </Form.Text>
                    }
                    {this.state.cvv.length !== 4 && this.state.cvv.length !== 0 && this.state.cardtype === "American Express" &&
                      <Form.Text className="text-muted" id="emailAlert">
                        Check CVV!
                      </Form.Text>
                    }
                    <Form.Label>Billing Address</Form.Label>
                    <Form.Control type="text" value={this.state.cardaddress} onChange={this.handleCardAddress} />
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" value={this.state.cardcity} onChange={this.handleCardCity} />
                    <Form.Label>State</Form.Label>
                    <Form.Control type="text" value={this.state.cardstate} onChange={this.handleCardState} />
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control type="text" value={this.state.cardzip} onChange={this.handleCardZip} />
                  </Form.Group>
                }
                {this.state.paymethod === "Check" &&
                  <Form.Group controlId="formCheck">
                    <Form.Label>Name on Check</Form.Label>
                    <Form.Control type="text" value={this.state.checkname} onChange={this.handleCheckName} />
                    <Form.Label>Check Number</Form.Label>
                    <Form.Control type="text" value={this.state.checknum} onChange={this.handleCheckNum} />
                    {this.state.checknum.length !== 9 && this.state.checknum.length !== 0 &&
                      <Form.Text className="text-muted" id="emailAlert">
                        Verify Check Number!
                      </Form.Text>
                    }
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control type="text" value={this.state.checkbank} onChange={this.handleCheckBank} />
                  </Form.Group>
                }
              </Form>
              {this.state.package === '' &&
                <Alert variant="danger">You need to select a Package!</Alert>
              }
              <OrderModal onClick={this.handleClose} cardtype={this.state.cardtype} cardname={this.state.cardname} cardnum={this.state.cardnum} expire={this.state.expire} cvv={this.state.cvv} cardaddress={this.state.cardaddress} cardcity={this.state.cardcity} cardstate={this.state.cardstate} cardzip={this.state.cardzip} dailycap={this.state.dailycap} bankname={this.state.bankname} checkname={this.state.checkname} checknum={this.state.checknum} checkbank={this.state.checkbank} isLoggedIn={this.props.isLoggedIn} package={this.state.package} paymethod={this.state.paymethod} special={this.state.special} retailprice={this.state.retailprice} discountprice={this.state.discountprice} consultant={this.state.consultant} code={this.state.code} ip={this.props.ip} />
              </Form>
              </>
            }
            </Modal.Body>
          </Modal>
          </>
        );
      }
    }
export default PayModalLink;
