import React, {Component} from 'react';

class CustomerProfile extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
        	showContent: false,
        	showLogin: false
        };
    }

    handleClose() {
        this.setState({ showContent: false, showLogin:false });
    }

    handleShow() {
      if (this.props.isLoggedIn === true){
        this.setState({ showContent: true });
      } else {
        this.setState({showLogin:true});
      }
    }

    render(){
        return (
        <>
        <div>
          <h3>{this.props.customer.fullname}'s Account</h3>
          <ul>
            <li>Company Name: {this.props.customer.company}</li>
            <li>Original Website: <a href={this.props.customer.site} target="_blank" rel="noopener noreferrer">{this.props.customer.site}</a></li>
            <li>SEO Website: <a href={this.props.customer.seoSite} target="_blank" rel="noopener noreferrer">{this.props.customer.seoSite}</a></li>
            <hr />
            <li><strong>Contact Info:</strong></li>
            <li>Email: {this.props.customer.emailaddress}</li>
            <li>Phone: {this.props.customer.phone}</li>
            <li>Address: {this.props.customer.address} {this.props.customer.city}, {this.props.customer.state} {this.props.customer.zip}</li>
            <hr />
            <li>Account Type: {this.props.customer.package}</li>
            <li>Username: {this.props.customer.username}</li>
          </ul>
        </div>
        </>
      )
    }
  }
export default CustomerProfile;
