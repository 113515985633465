import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap';
import ClientTerms from './ClientTerms';

export default function ACTerms(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return(
    <>
    <Button variant="danger" onClick={handleShow}>See Terms</Button>

    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Keyterms for {props.customer.fullname}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ClientTerms customer={props.customer} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}
