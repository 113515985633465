import React, {Component} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import SearchResult from './SearchResult';
import fb from './images/fb.png';
import insta from './images/insta.png';
import yt from './images/yt.png';
import twit from './images/xlogo.png';
import goog from './images/google.png';
import aol from './images/aol.png';
import ebay from './images/ebay.png';
import bing from './images/bing.png';
import yahoo from './images/yahoo.png';
import phone from './images/phone.png';
import bulb from './images/market.png';

class DemoSubmit extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleURL = this.handleURL.bind(this);
        this.handleIndustry = this.handleIndustry.bind(this);
        this.handleSocial = this.handleSocial.bind(this);
        this.handleArea = this.handleArea.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        this.state = {
            show: false,
            url: '',
            industry: '',
            social: '',
            area: '',
            codeCheck: ''
        };
    }

    componentDidMount(){
      this.setState({timestamp: new Date().toUTCString()})
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
      this.setState({ show: true });
    }

    handleURL(event) {
      this.setState({url: event.target.value});
    }

    handleIndustry(event){
      this.setState({industry: event.target.value});
    }

    handleSocial(e){
      this.setState({social: e.target.value});
    }

    handleArea(e){
      this.setState({area: e.target.value});
    }

    handleCheck(e){
      this.setState({codeCheck: e.target.value});
    }

    render(){
        return (
        <>
        <img onClick={this.handleShow} src={bulb} height="200" width="200" alt="Phone Icon" />
        <Modal size="xl" show={this.state.show} onHide={this.handleClose} id="layer3" backdrop={false}>
            <Modal.Header closeButton>
                <Modal.Title><h4>How It Works</h4></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <div className="col-4"><img src={goog} alt="Google Logo" /></div>
                  <div className="col-4"><img src={bing} alt="Bing Logo" /></div>
                  <div className="col-4"><img src={yahoo} alt="Yahoo Logo" /></div>
                </div>
                <div className="row">
                  <div className="col-4"><img src={fb} alt="Facebook Logo" /></div>
                  <div className="col-4"><img src={twit} alt="Twitter/X Logo" /></div>
                  <div className="col-4"><img src={insta} alt="Instagram Logo" /></div>
                </div>
              </div>

              <hr />
              <div className="container">
                <h4>See Your Potential</h4>
              </div>
              <div className="container">
                <Form>
                  <Form.Group controlId="demoCheck">
                    <Form.Label>Input Your Demo Code</Form.Label>
                    <Form.Control type="text" onChange={this.handleCheck} />
                  </Form.Group>
                  {this.state.codeCheck === this.props.dCode &&
                    <>
                    <Form.Group controlId="formDemo">
                      <Form.Label>Website URL</Form.Label>
                      <Form.Control type="text" value={this.state.url} onChange={this.handleURL} />
                      <Form.Label>Industry</Form.Label>
                      <Form.Control type="text" value={this.state.industry} onChange={this.handleIndustry} />
                      <Form.Label>What Social Media Platform Would You Like Help With?</Form.Label>
                      <Form.Control as="select" onChange={this.handleSocial}>
                        <option>Choose One...</option>
                        <option>Facebook</option>
                        <option>Twitter</option>
                        <option>Instagram</option>
                        <option>YouTube</option>
                      </Form.Control>
                      <Form.Label>Where are you located?</Form.Label>
                      <Form.Control type="text" onChange={this.handleArea} />
                      <a href={"https://www.google.com/search?source=ig&hl=en&rlz=&q=" + this.state.industry + "+" + this.state.social + "+" + this.state.area + "&btnG=Google+Search&start=00&nfpr=1"} target="_blank" rel="noopener noreferrer"><Button variant="primary">See Competition</Button></a>
                    </Form.Group>
                    </>
                  }
                </Form>
              </div>
            </Modal.Body>
        </Modal>
        </>
          );
      }
  }

export default DemoSubmit;
