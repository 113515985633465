import React, {Component} from 'react';
import {Button, Modal, Form, Accordion, Card, Alert} from 'react-bootstrap';
import Thanks from './thanks.js';

class OrderModal extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCompany = this.handleCompany.bind(this);
        this.handleSite = this.handleSite.bind(this);
        this.handleContact = this.handleContact.bind(this);
        this.handleFullname = this.handleFullname.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleSame = this.handleSame.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.handleCity = this.handleCity.bind(this);
        this.handleState = this.handleState.bind(this);
        this.handleZip = this.handleZip.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleTerms = this.handleTerms.bind(this);
        this.handleSig = this.handleSig.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUsername = this.handleUsername.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handlePassCheck = this.handlePassCheck.bind(this);
        this.handleIp = this.handleIp.bind(this);
        this.handleIndustry = this.handleIndustry.bind(this);

        this.state = {
            show: false,
            company: '',
            siteurl: '',
            contactname: '',
            fullname: '',
            emailaddress: '',
            emailconfirm: '',
            isSameBill: false,
            address: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            terms: false,
            signature: '',
            cardtype: '',
            cardname: '',
            cardnum: '',
            expire: '',
            cvv: '',
            cardaddress: '',
            cardcity:'',
            cardstate: '',
            cardzip: '',
            dailycap: '',
            bankname: '',
            checkname: '',
            checknum: '',
            checkbank: '',
            submitted: false,
            username: '',
            password: '',
            passwordcheck: '',
            ip: '',
            industry: ''
        };
    }

    componentWillMount(){
      this.handleIp();
    }

    handleCompany(event) {
      this.setState({company: event.target.value});
    }

    handleIndustry(event){
      this.setState({industry: event.target.value});
    }

    handleSite(event) {
      this.setState({siteurl: event.target.value});
    }

    handleContact(event) {
      this.setState({contactname: event.target.value});
    }

    handleFullname(event) {
      this.setState({fullname: event.target.value});
    }

    handleEmail(event) {
      this.setState({emailaddress: event.target.value});
    }

    handleConfirm(event) {
      this.setState({emailconfirm: event.target.value});
    }

    handleSame(event) {
      this.setState({isSameBill: !this.state.isSameBill});
    }

    handleUsername(event){
      this.setState({username: event.target.value});
    }

    handlePassword(event){
      this.setState({password: event.target.value});
    }

    handlePassCheck(event){
      this.setState({passwordcheck: event.target.value});
    }

    handleIp(){
      fetch('/ip')
      .then(res => res.json()
        .then(body => {
          if (res.ok) {
            this.setState({ip: body.ip.toString()})
          }
      }))
    }

    handleToggle(){
      this.setState({
        address: this.props.cardaddress,
        city: this.props.cardcity,
        state: this.props.cardstate,
        zip: this.props.cardzip
      });
    }

    handleAddress(event) {
      this.setState({address: event.target.value});
    }

    handleCity(event) {
      this.setState({city: event.target.value});
    }

    handleState(event) {
      this.setState({state: event.target.value});
    }

    handleZip(event) {
      this.setState({zip: event.target.value});
    }

    handlePhone(event) {
      this.setState({phone: event.target.value});
    }

    handleTerms(event) {
      this.setState({terms: !this.state.terms});
    }

    handleSig(event) {
      this.setState({signature: event.target.value});
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
      if(document.getElementById('layer6')){
        document.getElementById('layer6').style.display = 'none';
      }
        this.setState({ show: true });
    }

    handleSubmit(){
      let orderdate = new Date().toDateString();
      const pkg = {
        company: this.state.company,
        site: this.state.siteurl,
        contactname: this.state.contactname,
        fullname: this.state.fullname,
        emailaddress: this.state.emailaddress,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        phone: this.state.phone,
        consultant: this.props.consultant,
        ordercode: this.props.code,
        cardtype: this.props.cardtype,
        cardname: this.props.cardname,
        cardnum: this.props.cardnum,
        expire: this.props.expire,
        cvv: this.props.cvv,
        cardaddress: this.props.cardaddress,
        cardcity: this.props.cardcity,
        cardstate: this.props.cardstate,
        cardzip: this.props.cardzip,
        dailycap: this.props.dailycap,
        bankname: this.props.bankname,
        checkname: this.props.checkname,
        checknum: this.props.checknum,
        checkbank: this.props.checkbank,
        zellename: this.props.zellename,
        zellebank: this.props.zellebank,
        package: this.props.package,
        special: this.props.special,
        retailprice: this.props.retailprice,
        discountprice: this.props.discountprice,
        paymethod: this.props.paymethod,
        date: orderdate,
        username: this.state.username,
        password: this.state.password,
        ip: this.state.ip,
        industry: this.state.industry,
        sig: this.state.signature
      }
      fetch('/ordersubmit', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
      })
      .then(
        this.setState({submitted: true})
      )
    }

    render(){
        return (
        <>
        <Button onClick={this.handleShow}>Order Now</Button>
        <Modal show={this.state.show} onHide={this.handleClose} size="xl" id="last" backdrop={false}>
          <Modal.Header closeButton>
              <Modal.Title>Become Found Online!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formContactInfo" id="contactinfo">
                <Form.Label>Company Name</Form.Label>
                <Form.Control type="text" value={this.state.company} onChange={this.handleCompany} />
                <Form.Label>Industry Type</Form.Label>
                <Form.Control type="text" value={this.state.industry} onChange={this.handleIndustry} />
                <Form.Label>Website URL</Form.Label>
                <Form.Control type="text" value={this.state.siteurl} onChange={this.handleSite} />
                <Form.Text className="text-muted">For best results, copy (ctrl+c (Windows) or command+c (Mac) and paste (ctrl+v (Windows) or command+v (Mac)) the url from your web browser.)</Form.Text>
                <Form.Label>Contact Name</Form.Label>
                <Form.Control type="text" value={this.state.contactname} onChange={this.handleContact} />
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text" value={this.state.fullname} onChange={this.handleFullname} />
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" value={this.state.emailaddress} onChange={this.handleEmail} />
                {this.state.emailaddress !== this.state.emailconfirm &&
                  <Form.Text className="text-muted" id="emailAlert">
                    Emails Must Match!
                  </Form.Text>
                }
                <Form.Label>Confirm Email</Form.Label>
                <Form.Control type="email" value={this.state.emailconfirm} onChange={this.handleConfirm} />
                {this.state.emailaddress !== this.state.emailconfirm &&
                  <Form.Text className="text-muted" id="emailAlert">
                    Emails Must Match!
                  </Form.Text>
                }
                <Form.Check type="checkbox" label="Same as Billing?" value={this.state.isSameBill} onClick={this.handleSame} onChange={this.handleToggle} />
                <Form.Label>Street Address</Form.Label>
                {this.state.isSameBill &&
                  <Form.Control type="text" value={this.props.cardaddress} onChange={this.handleAddress} />
                }
                {this.state.isSameBill === false &&
                  <Form.Control type="text" value={this.state.address} onChange={this.handleAddress} />
                }
                <Form.Label>City</Form.Label>
                {this.state.isSameBill &&
                  <Form.Control type="text" value={this.props.cardcity} onChange={this.handleCity} />
                }
                {this.state.isSameBill === false &&
                  <Form.Control type="text" value={this.state.city} onChange={this.handleCity} />
                }
                <Form.Label>State</Form.Label>
                {this.state.isSameBill &&
                  <Form.Control type="text" value={this.props.cardstate} onChange={this.handleState} />
                }
                {this.state.isSameBill === false &&
                  <Form.Control type="text" value={this.state.state} onChange={this.handleState} />
                }
                <Form.Label>Zip Code</Form.Label>
                {this.state.isSameBill &&
                  <Form.Control type="text" value={this.props.cardzip} onChange={this.handleZip} />
                }
                {this.state.isSameBill === false &&
                  <Form.Control type="text" value={this.state.zip} onChange={this.handleZip} />
                }
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="text" value={this.state.phone} onChange={this.handlePhone} />
              </Form.Group>
              <Accordion>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <h6>Terms and Conditions</h6>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ul>
                        <li><strong>I understand and agree to the following:</strong></li>
                        <li>The payment method I have entered will be charged for the amount I have entered and understand that this is custom work.</li>
                        <li>We are providing you with a brand new website to promote your business and products through the world wide web and we do not guarantee or imply any amount of leads or sales that will be produced from this custom website / content.</li>
                        <li>This agreed to transaction will be for developing a custom website as per my order and I understand that due to the specific design and content of my new website I agree to a no refund no cancellation process.</li>
                        <li>The custom design and content of the custom website I am ordering will be determined on the website review call.</li>
                        <li>I understand and agree to a $15 per month maintenance fee.</li>
                        <li>Per this order there will be 2 revisions allowed after the review and page selection communication session and I agree that because of the custom design and content that the work can only be utilized on one product / business and can’t be returned or refunded.</li>
                        <li>Your electronic acceptance of this agreement signifies that you have read and agree to the above terms and conditions.</li>
                        {this.props.code === 'sbpre2024' &&
                        <li>First Page Placement is guaranteed for six months, or the second six months are free. If the guarantee is not met after twelve months, your initial investment will be fully returned.</li>
                        }
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Form.Group controlId="formSign">
                <Form.Check type="checkbox" value={this.state.terms} onChange={this.handleTerms} label="I have read and agree to the above Terms and Conditions." />
                <Form.Label>Signature</Form.Label>
                <Form.Control type="text" placeholder="Your Full Name" value={this.state.signature} onChange={this.handleSig} />
                <Form.Text className="text-muted">
                  Your IP Address: {this.state.ip}
                </Form.Text>
              </Form.Group>
              {this.state.fullname === '' && this.state.emailaddress === '' &&
                <Alert variant="warning">You must input your name and email address!</Alert>
              }
              {this.state.terms === false || this.state.signature === '' &&
                <Button variant="danger">You must accept the Terms and Conditions to continue.</Button>
              }
              {this.state.terms === true && this.state.signature !== '' && this.state.submitted === false &&
                <Button variant="secondary" onClick={this.handleSubmit}>Complete Order</Button>
              }
              {this.state.submitted === true &&
                <Thanks />
              }
            </Form>
          </Modal.Body>
        </Modal>
        </>
        );
    }
}

export default OrderModal;
