import React, {Component} from 'react';
import {Button, Modal, Nav, Form} from 'react-bootstrap';

class LoginModal extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLoginEmail = this.handleLoginEmail.bind(this);
        this.handleLoginPass = this.handleLoginPass.bind(this);
        this.handleAdmin = this.handleAdmin.bind(this);
        this.handleLogin = this.handleLogin.bind(this);

        this.state = {
            show: false,
            submitEmail: '',
            submitPass: '',
            isAdmin: false,
            adminLoginEmail: '',
            correctAdminEmail: 'admin@thefirstpagegroup.com',
            correctAdminPass: 'apassword',
            customerLoginEmail: '',
            customerLoginPass: '',
            adminLoginPass: '',
            adminIsLoggedIn: false,
            isLoggedIn: false,
            loginFail: false,
            customer: []
        };
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleLoginEmail(event){
      this.setState({submitEmail: event.target.value});
    }

    handleLoginPass(event){
      this.setState({submitPass: event.target.value});
    }

    handleAdmin(){
      this.setState({isAdmin: !this.state.isAdmin});
    }

    handleLogin() {
      // if(this.state.isAdmin){
      //     if(this.state.submitEmail === this.state.correctAdminEmail && this.state.submitPass === this.state.correctAdminPass){
      //       this.setState({adminIsLoggedIn: true});
      //     } else {
      //       this.setState({loginFail: true});
      //     }
      // } else {
      //   let i = 0;
      //   for(i in this.props.customers){
      //     if(this.props.customers[i].username === this.state.submitEmail && this.props.customers[i].password === this.state.submitPass){
      //       if (this.props.customers[i].username !== 'thefirstpagegroup' && this.props.customers[i].username !== 'becomefound'){
      //         this.setState({loginFail: false, isLoggedIn: true, customer: this.props.customers[i] }, () => {
      //           this.props.loginCallback(this.state.customer, this.state.isLoggedIn, this.state.isAdmin, this.state.isDemo);
      //         });
      //         let twoWeeks = new Date(Date.now() + 12096e5);
      //         document.cookie = "loginAs=" + this.props.customers[i].username + ";expires=" + twoWeeks.toUTCString();
      //         this.setState({show: false});
      //       } else if (this.props.customers[i].username === 'thefirstpagegroup'){
      //         this.setState({loginFail:false, isLoggedIn:true, customer: this.props.customers[i], isAdmin: true}, () => {
      //           this.props.loginCallback(this.state.customer, this.state.isLoggedIn, this.state.isAdmin, this.state.isDemo);
      //         });
      //         let twoWeeks = new Date(Date.now() + 12096e5);
      //         document.cookie = "loginAs=" + this.props.customers[i].username + ";expires=" + twoWeeks.toUTCString();
      //         this.setState({show: false});
      //       } else if (this.props.customers[i].username === 'becomefound'){
      //         this.setState({loginFail:false, isLoggedIn:true, customer:this.props.customers[i], isDemo: true}, () => {
      //           this.props.loginCallback(this.state.customer, this.state.isLoggedIn, this.state.isAdmin, this.state.isDemo);
      //         });
      //         this.setState({show: false});
      //       }
      //     }
      //   }
      // }
      const pkg = {
        username: this.state.submitEmail,
        password: this.state.submitPass,
      }
      fetch('/loginReq', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
      })
      .then(res => res.json()
        .then(body => {
          if (res.ok){
            if (body.username !== 'thefirstpagegroup' && body.username !== 'becomefound'){
              this.setState({customer:body, isLoggedIn: true,loginFail:false}, () => {
                this.props.loginCallback(this.state.customer, this.state.isLoggedIn, this.state.isAdmin, this.state.isDemo);
              })
            } else if (body.username === 'thefirstpagegroup'){
              this.setState({customer:body, isLoggedIn: true,loginFail:false, isAdmin:true}, () => {
                this.props.loginCallback(this.state.customer, this.state.isLoggedIn, this.state.isAdmin, this.state.isDemo);
              })
            } else if (body.username === 'becomefound'){
              this.setState({customer:body, isLoggedIn: true,loginFail:false, isDemo:true}, () => {
                this.props.loginCallback(this.state.customer, this.state.isLoggedIn, this.state.isAdmin, this.state.isDemo);
            })
          }
        }
      })
    )
  }

    render(){
        return (
        <>
        <Nav.Link onClick={this.handleShow}>Login</Nav.Link>
        <Modal show={this.state.show} onHide={this.handleClose} size="lg" id="loginwindow" backdrop={false}>
          <Modal.Header closeButton>
              <Modal.Title>Login To Your Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" onChange={this.handleLoginEmail} />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={this.handleLoginPass} />
              </Form.Group>
              {this.state.isLoggedIn === false &&
                <Button variant="primary" onClick={this.handleLogin}>Submit</Button>
              }
              {this.state.isLoggedIn &&
                <Button variant="success" onClick={this.handleClose}>Return</Button>
              }
              {this.state.loginFail &&
                <p id="loginfail">Email or Password incorrect, try again.</p>
              }
            </Form>
          </Modal.Body>
        </Modal>
        </>
        );
      }
  }

export default LoginModal;
