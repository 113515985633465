import React, {Component} from 'react';

class RevCounter extends Component {
  constructor(props){
    super(props);
    this.state = {
      rev: 133787211
    };
  }

  componentDidMount(){
    setInterval(() => {
      let reve = this.state.rev;
      reve = reve * 1.001;
      this.setState({rev: reve});
    }, 60000);
  }

  render() {
    let revenue = this.state.rev;
    revenue = Math.trunc(revenue);
    revenue = revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return(
      <div id="revBox">
        <div id="revNum">
          <h4>${revenue}</h4>
        </div>
        <div id="revText">
          <h4>Total Revenue Generated for Our Clients</h4>
        </div>
      </div>
    );
  }
}

export default RevCounter;
