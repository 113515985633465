import React, {Component} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';

class AreaSelection extends Component {
  constructor(props, context){
    super(props, context);

    this.handleSelection = this.handleSelection.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleSameCard = this.handleSameCard.bind(this);
    // this.handleCardNameChange = this.handleCardNameChange.bind(this);
    // this.handleCardNumChange = this.handleCardNumChange.bind(this);
    // this.handleExpChange = this.handleExpChange.bind(this);
    // this.handleCVVChange = this.handleCVVChange.bind(this);
    // this.handleAgreement = this.handleAgreement.bind(this);
    // this.handleSignChange = this.handleSignChange.bind(this);
    // this.handleCompletion = this.handleCompletion.bind(this);

    this.state = {
      selectedAreas: [],
      completeTerms: [],
      submitted: false,
      fail: false
    }
  }

  handleSelection(idfetch, e){
    let terms = this.props.terms;
    let fullTerm = "";
    let currentTerm = document.getElementById(idfetch).textContent;
    let fullList = this.state.completeTerms;
    let area = e.target.value;
    let lastFull = '';
    let lastTerm = '';
    if (fullList.length !== 0){
      lastFull = fullList[fullList.length-1];
      lastTerm = lastFull.slice(0, currentTerm.length);
    }
    let k = 0;
    for (k in terms){
      if (terms[k] === currentTerm) {
        if (this.state.completeTerms[k] === lastFull){
          this.state.completeTerms.pop();
        }
        fullTerm = terms[k] + " " + area;
        fullList.push(fullTerm);
      }
    }
    this.setState({completeTerms:fullList});
  }

  handleSubmit(){
    if (this.props.customer.package === 'Silver'){
      if (this.state.completeTerms.length === 10){
        const pkg = {
          username: this.props.customer.username,
          fullname: this.props.customer.fullname,
          level: this.props.customer.package,
          terms: this.state.completeTerms,
          hasTerms: 'true'
        }
        fetch('/termsUpdate', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
          },
          body: JSON.stringify(pkg)
        })
        .then(
          this.setState({submitted:true, fail:false})
        )
      } else {
        this.setState({fail:true});
      }
    } else if (this.props.customer.package === 'Platinum'){
      if (this.state.completeTerms.length === 20){
        const pkg = {
          username: this.props.customer.username,
          fullname: this.props.customer.fullname,
          level: this.props.customer.package,
          terms: this.state.completeTerms,
          hasTerms: true
        }
        fetch('/termsUpdate', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
          },
          body: JSON.stringify(pkg)
        })
        .then(
          this.setState({submitted:true, fail:false})
        )
      } else {
        this.setState({fail:true});
      }
    } else if (this.props.customer.package === 'Emerald'){
      if (this.state.completeTerms.length === 40){
        const pkg = {
          username: this.props.customer.username,
          fullname: this.props.customer.fullname,
          level: this.props.customer.package,
          terms: this.state.completeTerms,
          hasTerms: true
        }
        fetch('/termsUpdate', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
          },
          body: JSON.stringify(pkg)
        })
        .then(
          this.setState({submitted:true, fail:false})
        )
      } else {
        this.setState({fail:true});
      }
    }
  }

  // handleSameCard(e){
  //   let sameCard = !this.state.isSameCard;
  //   this.setState({isSameCard:sameCard});
  // }
  //
  // handleCardNameChange(e){
  //   this.setState({cardname:e.target.value});
  // }
  //
  // handleCardNumChange(e){
  //   this.setState({cardnum:e.target.value});
  // }
  //
  // handleExpChange(e){
  //   this.setState({expire:e.target.value});
  // }
  //
  // handleCVVChange(e){
  //   this.setState({cvv:e.target.value});
  // }
  //
  // handleAgreement(e){
  //   let switcher = !this.state.agreed;
  //   this.setState({agreed:switcher});
  // }
  //
  // handleSignChange(e){
  //   this.setState({sig:e.target.value});
  // }
  //
  // handleCompletion(){
  //   console.log(this.state.expire);
  //   if (this.state.sig !== this.props.customer.fullname){
  //     this.setState({sigProb: true});
  //   } else if (this.state.sig === this.props.customer.fullname){
  //     this.setState({sigProb: false});
  //     if (this.state.cardnum.length === 16 && this.state.cvv.length > 2 && this.state.cvv.length < 5){
  //       this.setState({cardProb: false});
  //       const pkg = {
  //         fullname: this.props.customer.fullname,
  //         sig: this.state.sig,
  //         cardname: this.state.cardname,
  //         cardnum: this.state.cardnum,
  //         exp: this.state.expire,
  //         cvv: this.state.cvv,
  //         agreed: this.state.agreed,
  //         terms: this.state.completeTerms
  //       }
  //       fetch('/agreement', {
  //         method: 'POST',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Content-type': 'application/json',
  //         },
  //         body: JSON.stringify(pkg)
  //       });
  //     } else {this.setState({cardProb:true});}
  //   }
  // }

  render(){
    return (
      <>
      {this.state.submitted === false &&
        <>
        <FormRender customer={this.props.customer} terms={this.props.terms} handleSelection={this.handleSelection} />
        <Button variant="success" onClick={this.handleSubmit}>Submit Your Terms</Button>
        </>
      }
      {this.state.submitted === true &&
        <>
        <Alert variant="success">You have successfully selected your keyterms! Refresh the page and click on 'My Account', then 'My Keyterms' to see your keyterms.</Alert>
        </>
      }
      </>
    )
  }
}

class FormRender extends Component {
  constructor(props, context){
    super(props,context);
  }

  render(){
    let formRows = [];
    let j = 0;
    for (j in this.props.terms){
      let idkey = "term" + j.toString();
      let termkey = "select" + j.toString();
      formRows.push(
        <>
        <Form.Label id={idkey}>{this.props.terms[j]}</Form.Label>
        <Form.Control as="select" id={termkey} onChange={(e) => this.props.handleSelection(idkey, e)}>
          <AreaRender customer={this.props.customer} />
        </Form.Control>
        </>
      )
    }
    return(<>{formRows}</>)
  }
}

class AreaRender extends Component {
  constructor(props, context) {
    super(props, context);

    this.customerData = this.customerData.bind(this);

    this.state = {
      areas: [],
      videos:[]
    };
  }

  // <div className="col-lg-4">
  //   <img src={this.state.testimonials[i].image} alt={this.state.testimonials[i].name} width="150" height="150" />
  // </div>

  componentWillMount(){
    // console.log(this.props.customer);
    this.customerData();
    // console.log(this.state.terms);
  }

  customerData() {
    let j=0;
    fetch('/customerAreas')
    .then(res => res.json()
      .then(body => {
        if (res.ok)
          for (j in body){
            if (body[j].fullname === this.props.customer.fullname && body[j].username === this.props.customer.username){
              this.setState({areas: body[j]});
            }
          }
        // throw new Error(body.err)
      })
    )
  }
    render(){
      let rows = [<option>Select an Area</option>];
      let i = 0;
      for ( i in this.state.areas ){
        let currentKey = "this.state.areas." + i.toString();
        let currentArea = this.state.areas[i];
        if (currentKey !== 'this.state.areas.area0' && this.state.areas[i] !== null && currentKey !== 'this.state.areas.username' && currentKey !== 'this.state.areas.fullname' && currentKey !== 'this.state.areas.package'){
          rows.push(
            <option>{currentArea}</option>
          )
        }
      }
      return(<>{rows}</>)
    }
  }
export default AreaSelection;
