import React, {Component} from 'react';
import {Button, Modal, Form, Alert} from 'react-bootstrap';

class LeadModal extends Component{
  constructor(props, context) {
      super(props, context);

      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleConsult = this.handleConsult.bind(this);
      this.handleDispo = this.handleDispo.bind(this);
      this.handleAction = this.handleAction.bind(this);
      this.handleNotes = this.handleNotes.bind(this);

      this.state = {
          show: false,
          consultant: this.props.lead.consultant,
          dispo: this.props.lead.disposition,
          action: this.props.lead.action,
          notes: this.props.lead.notes
      };
  }

  handleClose() {
      this.setState({ show: false });
  }

  handleShow() {
      this.setState({ show: true });
  }

  handleConsult(e){
    this.setState({consultant: e.target.value});
  }

  handleDispo(e){
    this.setState({dispo: e.target.value});
  }

  handleAction(e){
    this.setState({action: e.target.value});
  }

  handleNotes(e){
    this.setState({notes: e.target.value});
  }

  handleSubmit(){
    const pkg = {
      name: this.props.lead.name,
      consultant: this.state.consultant,
      dispo: this.state.dispo,
      action: this.state.action,
      notes: this.state.notes
    }
    this.props.leadUpdate(this.props.lead.name, this.state.consultant, this.state.dispo, this.state.action, this.state.notes, 0);
    fetch('/leadUpdate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(pkg)
    })
    .then(
      this.setState({show:false})
    )
  }

  render(){
    return (
      <>
      <Button variant="dark" onClick={this.handleShow}>Update Lead</Button>
      <Modal size="xl" show={this.state.show} onHide={this.handleClose} backdrop={false}>
        <Modal.Header closeButton>
          <h3>Notes and Controls for {this.props.lead.name}</h3>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning">This tool will update these values for the selected lead. If you don't want to update one of these, do not change the field for it!</Alert>
          <h5>Lead Notes:</h5>
          <p>{this.props.lead.notes}</p>
          <ul>
            <li>Experience: {this.props.lead.exp}</li>
            <li>Rating: {this.props.lead.rating}</li>
            <li>Price Range: {this.props.lead.pricerange}</li>
            <li># of Homes for Sale: {this.props.lead.forsale}</li>
            <li># of Homes Sold: {this.props.lead.sold}</li>
            <li>Last Listing: {this.props.lead.lastlist}</li>
          </ul>
          <Form>
            <Form.Group>
              <Form.Label>Assign to:</Form.Label>
              <Form.Control type="text" placeholder="Consultant Name" onChange={this.handleConsult} value={this.state.consultant} />
              <Form.Label>Disposition:</Form.Label>
              <Form.Control as="select" onChange={this.handleDispo} value={this.state.dispo}>
                <option>Select One</option>
                <option>Hot</option>
                <option>Warm</option>
                <option>Cold</option>
                <option>Dead</option>
                <option>New</option>
              </Form.Control>
              <Form.Label>Next Action:</Form.Label>
              <Form.Control as="select" onChange={this.handleAction} value={this.state.action}>
                <option>Select One</option>
                <option>Call</option>
                <option>Demo</option>
                <option>Order</option>
                <option>Keyterms</option>
                <option>Maintain</option>
                <option>Dead Lead</option>
              </Form.Control>
              <Form.Label>Update Notes:</Form.Label>
              <Form.Control type="text" placeholder="This will replace current notes, not update them! Try to limit length to a Tweet (140 characters)." onChange={this.handleNotes} value={this.state.notes} />
            </Form.Group>
          </Form>
        <Button variant="primary" onClick={this.handleSubmit}>Submit Changes</Button>
        </Modal.Body>
      </Modal>
      </>
    )
  }
}

export default LeadModal;
