import React, {useState, useEffect} from 'react';
import {Modal, Button, Form, Table, Accordion, Card, Nav} from 'react-bootstrap';
import ReactPlayer from 'react-player';
import PayModal from './PaymentModal';
import globevid from './images/globe.mp4';
import impress from './images/impress.png';
import logo from './images/logo.png';
import leo from './images/leo.png';
import fb from './images/fb.png';
import twit from './images/twit.png';
import insta from './images/insta.png';
import google from './images/google.png';
import yahoo from './images/yahoo.png';
import bing from './images/bing.png';
import reddit from './images/reddit.png';
import duck from './images/duck.png';
import yt from './images/yt.png';
import chart from './images/metrics.png';
import idx from './images/idx.png';
import phone from './images/phone.png';
import funnel from './images/bsfunnel.png';
import map from './images/map.png';
import yes from './images/yes.png';
import no from './images/no.png';
import creds from './images/support.png';
import cards from './images/credit.png';
import visa from './images/visa.png';
import master from './images/master.png';
import amex from './images/amex.png';
import discover from './images/discover.png';
import bulb from './images/orb.png';
import review from './images/newcred2.png';
import test1 from './images/victoria.jpg';
import test2 from './images/peter.jpg';
import test3 from './images/leo.jpg';
import test4 from './images/erica.jpg';
import test5 from './images/maryv.jpg';
import test6 from './images/eugene.jpg';

export default function NavDemo(props){
  const ref = React.useRef(null);

  const [show, setShow] = useState(false);
  const [showSbs, setShowSbs] = useState(false);
  const [demo, setDemo] = useState({city: '', area2: '', area3: '', avgprof: ''});
  const [info, setInfo] = useState({dcode: props.dCode});
  const [step3, setStep3] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [codeIn, setCodeIn] = useState(false);
  const [inIn, setInIn] = useState(false);
  const [infoSub, setInfoSub] = useState(false);
  const [updater, setUpdater] = useState(false);
  const [compOut, setCompOut] = useState(false);
  const [pro, setPro] = useState(false);
  const [roi, setRoi] = useState(false);
  const [credOut, setCredOut] = useState(false);
  const [storefrontYes, setStorefrontYes] = useState(false);
  const [storefrontNo, setStorefrontNo] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSbs = () => setShowSbs(true);
  const handleSbsClose = () => setShowSbs(false);

  const handleChange = (e) => {
    let changer = demo;
    changer[e.target.name] = e.target.value;
    changer.avgprof = changer.avgprof.replace(/\D+/g, '');
    setDemo(changer);
    setUpdater(!updater);
  }
  const handleStep3 = () => setStep3(true);
  const handleCodeIn = () => setCodeIn(true);
  const handleIndustry = () => setInIn(true);
  const handleInfo = () => setInfoSub(true);
  const handleOut = () => {setCompOut(true); setInIn(false);};
  const handleCred = () => {setCompOut(false); setCredOut(true); setTimeout(() => ref.current.scrollIntoView({behavior:'smooth'}),500);};
  const handlePromo = () => {if(typeof(demo.democheck2) === 'string'){setPro(true); setCompOut(false); setInIn(true)}};
  const handleRoi = () => {setRoi(true); setPro(false);};
  const handleStorefrontYes = () => {setStorefrontYes(true); setStorefrontNo(false);};
  const handleStorefrontNo = () => {setStorefrontYes(false); setStorefrontNo(true);};

  const handleSbsSub = () => {
    let storefront = '';
    if(storefrontYes && !storefrontNo){
      storefront = 'Yes';
    } else if(storefrontNo && !storefrontYes){
      storefront = 'No';
    }
    const sbspkg = {
      sbsemployees: demo.sbsemployees,
      sbsrevenue: demo.sbsrevenue,
      sbsstorefront: storefront,
      sbsbiztime: demo.sbsbiztime
    }
    console.log(sbspkg.sbsrevenue, sbspkg.sbsstorefront);

    fetch('/sbssubmit', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(sbspkg)
    })
    .then(
      console.log("SBS Success"),
      setShowSbs(false)
    )
  }

  useEffect(() => {
    console.log(props.dCode === demo.dcode, step3);
    if (demo.industry === "Real Estate"){
      let searchbuild = '';
      let citybreaker = demo.city.split(' ');
      let citybuilder = citybreaker.join('+');
      console.log(citybuilder);
      if (demo.clienttype === 'Buyers'){
        searchbuild = "https://www.google.com/search?&q=" + "homes+for+sale+in+" + citybuilder;
      } else if (demo.clienttype === 'Sellers'){
        searchbuild = "https://www.google.com/search?&q=" + "listing+agent+" + citybuilder;
      } else if (demo.clienttype === 'Investors'){
        searchbuild = "https://www.google.com/search?&q=" + "investment+properties+in+" + citybuilder;
      }
      console.log(searchbuild);
      setSearchString(searchbuild);
    } else if(demo.industry === "Home Business" || demo.industry === "Real Estate 2"){
      let searchbuild = '';
      let areabreaker = demo.area3.split(' ');
      let areabuilder = areabreaker.join('+');
      searchbuild = "https://www.google.com/search?&q=" + areabuilder;
      setSearchString(searchbuild);
    }
  })

  return(
    <>
    <Nav.Link onClick={handleShow}>1st Page</Nav.Link>

    <Modal size='xl' show={show} onHide={handleClose} background={"static"}>
      <Modal.Header closeButton>
        <Modal.Title ref={ref}>See How The First Page Group Can Help You Generate Sellers and Buyers Organically</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {step3 === false &&
            <>
            <div className="container">
              <div className="row">
                <div className="col-2"></div>
                <div className="col-8 funnel">
                  <img src={funnel} className="w-100" alt="Our marketing process." />
                <div className="col-2"></div>
                </div>
              </div>
            </div>
            <Form.Group controlId="democode">
              <Form.Label>Input Your Demo Code</Form.Label>
              <Form.Control type="text" onChange={handleChange} name="dcode" />
              <Button variant="success" onClick={handleStep3} name="step3" className="w-100 subbtn">Continue</Button>
            </Form.Group>
            </>
          }
          {demo.dcode === props.dCode && step3 && inIn === false && compOut === false && credOut === false && pro === false && roi === false &&
            <>
            <Form.Group controlId="infobox">
              <Form.Label>Enter Your Current Website URL</Form.Label>
              <Form.Control type="text" onChange={handleChange} name="curl" />
              <hr />
              <Form.Label>Industry</Form.Label>
              <Form.Control as="select" onChange={handleChange} name="industry">
                <option>Choose One...</option>
                <option value="Real Estate 2">Real Estate</option>
                <option>Home Business</option>
                <option value="Home Business">Insurance</option>
                <option value="Home Business">Contractors</option>
                <option value="Home Business">Fundraisers</option>
                <option value="Home Business">Financial</option>
                <option value="Home Business">Coach/Consultant</option>
                <option value="Home Business">Health and Wellness</option>
                <option value="Home Business">Retail Store</option>
                <option value="Home Business">Other</option>
              </Form.Control>
              <Button variant="success" onClick={handleIndustry} className="w-100 subbtn">Submit</Button>
            </Form.Group>
            </>
          }
          {demo.industry === "Home Business" && inIn && pro === false && roi === false &&
            <>
            <Form.Group controlId="hbinfo">
              <Form.Label>Areas of Focus</Form.Label>
              <hr />
              <Form.Label>Focus 1</Form.Label>
              <Form.Control type="text" name="area1" onChange={handleChange} />
              {demo.area2.length > 3 &&
                <Form.Text muted>Available!</Form.Text>
              }
              <hr />
              <Form.Label>Focus 2</Form.Label>
              <Form.Control type="text" name="area2" onChange={handleChange} />
              {demo.area3.length > 1 &&
                <Form.Text muted>Pending...</Form.Text>
              }
              <hr />
              <Form.Label>Focus 3</Form.Label>
              <Form.Control type="text" name="area3" onChange={handleChange} />
              {demo.avgprof.length > 1 &&
                <Form.Text muted>Available!</Form.Text>
              }
              <hr />
              <Form.Label>Average Enrollment</Form.Label>
              <Form.Control type="text" name="avgenrol" onChange={handleChange} />
              <hr />
              <Form.Label>Average Profit</Form.Label>
              <Form.Control type="text" name="avgprof" onChange={handleChange} />
              <hr />
              <Form.Label>Highest Profit</Form.Label>
              <Form.Control type="text" name="highen" onChange={handleChange} />
              <hr />
              <Form.Label>How are you currently advertising?</Form.Label>
              <Form.Control as="select" onChange={handleChange} name="advert">
                <option>Choose One...</option>
                <option>Online</option>
                <option>Offline</option>
                <option>Both</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" onClick={handleOut} className="w-100">Continue</Button>
            </>
          }
          {demo.industry === "Real Estate 2" && inIn && pro === false && roi === false &&
            <>
            <Form.Group controlId="hbinfo">
              <Form.Label>Areas of Focus</Form.Label>
              <hr />
              <Form.Label>Focus 1</Form.Label>
              <Form.Control type="text" name="area1" onChange={handleChange} />
              {demo.area2.length > 3 &&
                <Form.Text muted>Available!</Form.Text>
              }
              <hr />
              <Form.Label>Focus 2</Form.Label>
              <Form.Control type="text" name="area2" onChange={handleChange} />
              {demo.area3.length > 1 &&
                <Form.Text muted>Pending...</Form.Text>
              }
              <hr />
              <Form.Label>Focus 3</Form.Label>
              <Form.Control type="text" name="area3" onChange={handleChange} />
              {demo.avgprof.length > 1 &&
                <Form.Text muted>Available!</Form.Text>
              }
              <hr />
              <Form.Label>Average Transaction</Form.Label>
              <Form.Control type="text" name="avgenrol" onChange={handleChange} />
              <hr />
              <Form.Label>Average Profit</Form.Label>
              <Form.Control type="text" name="avgprof" onChange={handleChange} />
              <hr />
              <Form.Label>Highest Profit</Form.Label>
              <Form.Control type="text" name="highen" onChange={handleChange} />
              <hr />
              <Form.Label>How are you currently advertising?</Form.Label>
              <Form.Control as="select" onChange={handleChange} name="advert">
                <option>Choose One...</option>
                <option>Online</option>
                <option>Offline</option>
                <option>Both</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" onClick={handleOut} className="w-100">See Competition</Button>
            </>
          }
          {demo.industry === "Real Estate" && inIn && pro === false && roi === false &&
            <>
            <Form.Group controlId="reinfo">
              <Form.Label>What County Do You Work In?</Form.Label>
              <Form.Control type="text" onChange={handleChange} name="county" />
              {typeof(demo.county) === 'string' && typeof(demo.clienttype) !== 'string' &&
                <Form.Text muted>Checking...</Form.Text>
              }
              {typeof(demo.county) === 'string' && typeof(demo.clienttype) === 'string' &&
                <Form.Text muted>Available!</Form.Text>
              }
              <hr />
              <Form.Label>What City Do You Work in the Most?</Form.Label>
              <Form.Control type="text" onChange={handleChange} name="city" />
              <hr />
              <Form.Label>What Type of Clients Do You Prefer?</Form.Label>
              <Form.Control as="select" onChange={handleChange} name="clienttype">
                <option>Choose One...</option>
                <option>Buyers</option>
                <option>Sellers</option>
                <option>Investors</option>
              </Form.Control>
              <hr />
              <Form.Label>What is the Median Home Value in Your Area?</Form.Label>
              <Form.Control type="text" onChange={handleChange} name="medianval" placeholder="Enter just numbers, no $ or commas." />
              <hr />
              <Form.Label>What is your Average Profit Per Sale?</Form.Label>
              <Form.Control type="text" onChange={handleChange} name="avgprof" placeholder="Enter just numbers, no $ or commas." />
              <hr />
              <Form.Label>How many transactions do you typically complete in a year?</Form.Label>
              <Form.Control type="text" onChange={handleChange} name="trans" placeholder="Enter just numbers, no $ or commas." />
              <hr />
              <Form.Label>How are you currently advertising?</Form.Label>
              <Form.Control as="select" onChange={handleChange} name="advert">
                <option>Choose One...</option>
                <option>Online</option>
                <option>Offline</option>
                <option>Both</option>
              </Form.Control>
              <hr />
              <Button variant="primary" onClick={handleOut}>See Competition</Button>
            </Form.Group>
            </>
          }
          {compOut && inIn === false &&
            <>
            <div className="container mheadline">
              <h3>We Have Helped Many Businesses Throughout the World Become Found Online</h3>
            </div>
            <div className="container imgrack">
              <div className="row">
                <div className="col-2">
                  <img src={google} alt="Google" />
                </div>
                <div className="col-2">
                  <img src={yahoo} alt="Yahoo" />
                </div>
                <div className="col-2">
                  <img src={bing} alt="Bing" />
                </div>
                <div className="col-2">
                  <img src={duck} alt="DuckDuckGo" />
                </div>
                <div className="col-2">
                  <img src={reddit} alt="Reddit" />
                </div>
                <div className="col-2">
                  <img src={yt} alt="YouTube" />
                </div>
              </div>
            </div>
            <div className="container mheadline">
            <h3>WE CONNECT CLIENTS AROUND THE GLOBE</h3>
          </div>
          <ReactPlayer className="heroVid" url={globevid} playing loop muted width="100%" height="auto" />
          <img className="map" src={impress} alt="Areas the First Page Group serves." />
          
          <Button variant="success" onClick={handleCred} className="w-100">Continue</Button>
            </>
          }
          {credOut && inIn === false &&
            <>
            <Form.Group controlId="promocode">
              <div className="container imgrack2 cardrack">
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-8 usaf"><img src={review} alt="Google Reviews" className="w-100" /></div>
                  <div className="col-2"></div>
                </div>
              </div>
              <div className="container testboxl">
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-2"><img src={test1} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-8">
                    <h3>Veronica G.</h3>
                    <p>As a new business owner it can be difficult to navigate the advertising waters. We didn't know if we should do print or social media or paid ads on google. So glad that the 1st Pg Group call us and offered us this opportunity to become found online with out having to pay per click. We get calls, emails, and inquiries literally all hours of the day and night and business is booming. Thanks</p>
                  </div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-8">
                    <h3>Tim B.</h3>
                    <p>My Solar panel installations have been up by 60% this month! Has been a great experience working with everyone at first page, staff has been very helpful, glad to be on board business is booming!</p>
                  </div>
                  <div className="col-2"><img src={test2} className="rounded-circle w-100" alt="Review Poster" /></div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-2"><img src={test3} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-8">
                    <h3>Tommy D.</h3>
                    <p>So glad I decided to work with first page group, I was struggling to get people to my site that were qualified and ready and able to purchase. There customer service has been second to none, and you can call and speak with your actual rep that's in the U.S. Second month in a business is booming!</p>
                  </div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-8">
                    <h3>Bonnie P.</h3>
                    <p>First impression was the consultant seemed a little pushy which is why I only leave 4 stars but I'm 2 months in and have gotten several new accounts so I'm glad I decided to work with them.</p>
                  </div>
                  <div className="col-2"><img src={test4} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-2"><img src={test5} className="rounded-circle w-100" alt="Review Poster" /></div>
                  <div className="col-8">
                    <h3>Mary V.</h3>
                    <p>Love the service, I was working from home part-time and wasn't able to get my business off the ground until they helped me bring in enough buyers to now work from home full time I have more time for my family now. Such a great service.</p>
                  </div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-8">
                    <h3>Bob G.</h3>
                    <p>I have always been hesitant to advertise but the group has been an excellent decision for my Insurance agency I will recommend this to any insurance agent. Thanks for the stellar website and service!</p>
                  </div>
                  <div className="col-2"><img src={test6} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-1"></div>
                </div>
              </div>
              <Button variant="info" onClick={handleSbs} className="w-100">See If You Qualify for Our Small Business Stimulus!</Button>
          <Modal show={showSbs} onHide={handleSbsClose} background={"static"}>
            <Modal.Header closeButton>
              <Modal.Title>See If You Qualify for Our Small Business Stimulus!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="sbsform">
                  <Form.Label>Current Number of Employees:</Form.Label>
                  <Form.Control as="select" name="sbsemployees" onChange={handleChange}>
                    <option>Select One...</option>
                    <option>0 - 10</option>
                    <option>11 - 30</option>
                    <option>31+</option>
                  </Form.Control>
                  <hr />
                  <Form.Label>Revenue Over Last 6 Months:</Form.Label>
                  <Form.Control as="select" name="sbsrevenue" onChange={handleChange}>
                    <option>Select One...</option>
                    <option>Less Than $100,000</option>
                    <option>More Than $100,000</option>
                  </Form.Control>
                  <hr />
                  <Form.Label>Do You Have a Storefront?</Form.Label>
                  <Form.Check type="radio" onChange={handleStorefrontYes} label="Yes" />
                  <Form.Check type="radio" onChange={handleStorefrontNo} label="No" />
                  <hr />
                  <Form.Label>How Long Have You Been in Business?</Form.Label>
                  <Form.Control as="select" name="sbsbiztime" onChange={handleChange}>
                    <option>Select One...</option>
                    <option>Less Than 12 Months</option>
                    <option>More Than 12 Months</option>
                  </Form.Control>
                  <Button variant="success" onClick={handleSbsSub} className="w-100">Submit</Button>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>
              <Form.Label>Confirm Your Demo Code to Continue</Form.Label>
              <Form.Control type="text" name="democheck2" onChange={handleChange} />
            </Form.Group>
            <Button variant="success" onClick={handlePromo} className="w-100">Submit</Button>
            </>
          }
          {pro && compOut === false && demo.democheck2 === props.dCode &&
            <>
            {demo.industry === 'Real Estate 2' &&
              <img className="w-100" src={chart} alt="One of our clients IDX pages." />
            }
            {demo.industry === 'Home Business' &&
              <img className="w-100" src={chart} alt="Home Business Infographic" />
            }
            <div id="tabletitle" className="w-100">
              <h4>We have packages available for all sizes of business!</h4>
            </div>
            <Table striped bordered hover className="pkgtbl">
              <thead>
                <tr>
                  <th className="firstcol">Features</th>
                  <th>Starter</th>
                  <th>Standard</th>
                  <th>Business Builder</th>
                  <th>Elite</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="firstcol">Virtual Platform</td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
                <tr>
                  <td className="firstcol">Search Terms with Guaranteed 1st Page Integration</td>
                  <td><span className="pkgnum">2</span></td>
                  <td><span className="pkgnum">4</span></td>
                  <td><span className="pkgnum">8</span></td>
                  <td><span className="pkgnum">16</span></td>
                </tr>
                <tr>
                  <td className="firstcol">Virtual Sales Funnel Design</td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
                <tr>
                  <td className="firstcol">Social Media Integration</td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
                <tr>
                  <td className="firstcol">QR Code Integration</td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
                <tr>
                  <td className="firstcol">Conversion Consulting</td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
                <tr>
                  <td className="firstcol">YouTube Integration</td>
                  <td><img src={no} className="pkgimg" /></td>
                  <td><img src={no} className="pkgimg" /></td>
                  <td><img src={no} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
              </tbody>
            </Table>
            <div className="container pkgmobile">
              <div className="row">
                <div className="col-12">
                  <ul>
                    <li className="pkghead">Starter</li>
                    <li>Virtual Platform</li>
                    <li>2 Search Terms with Guaranteed 1st Page Integration</li>
                    <li>Virtual Sales Funnel Design</li>
                    <li>QR Code Integration</li>
                    <li>Conversion Consulting</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ul>
                    <li className="pkghead">Standard</li>
                    <li>Virtual Platform</li>
                    <li>4 Search Terms with Guaranteed 1st Page Integration</li>
                    <li>Virtual Sales Funnel Design</li>
                    <li>Social Media Integration</li>
                    <li>QR Code Integration</li>
                    <li>Conversion Consulting</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ul>
                    <li className="pkghead">Business Builder</li>
                    <li>Virtual Platform</li>
                    <li>8 Search Terms with Guaranteed 1st Page Integration</li>
                    <li>Virtual Sales Funnel Design</li>
                    <li>Social Media Integration</li>
                    <li>Conversion Consulting</li>
                    <li>QR Code Integration</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ul>
                    <li className="pkghead">Elite</li>
                    <li>Virtual Platform</li>
                    <li>16 Search Terms with Guaranteed 1st Page Integration</li>
                    <li>Virtual Sales Funnel Design</li>
                    <li>Social Media Integration</li>
                    <li>YouTube Integration</li>
                    <li>Social Media Training</li>
                    <li>Conversion Consulting</li>
                    <li>QR Code Integration</li>
                  </ul>
                </div>
              </div>
            </div>
            <Button variant="secondary" className="w-100" onClick={handleRoi}>See Your Profit Projection</Button>
            </>
          }
          {roi && pro === false && demo.industry === 'Real Estate' &&
            <>
            <h6>Based on your median home price of ${demo.medianval}, and your estimated yearly transaction amount, we estimate the following projected return on your investment:</h6>
            <h6>Our Formula: R = 0.03m(1.375r(1.3fr)) where R = ROI, m = median home value, r = conversion rate, and f = site traffic.</h6>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Package</th>
                  <th>Estimated ROI</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Standard</td>
                  <td>${(((demo.medianval * 0.03) * ((1.375*0.05) * (1.3 * (demo.trans / 0.05) * 0.05)) * 4.5)).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Business Builder</td>
                  <td>${(((demo.medianval * 0.03) * ((1.375*0.05) * (1.3 * (demo.trans / 0.05) * 0.05)) * 9)).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Elite</td>
                  <td>${(((demo.medianval * 0.03) * ((1.375*0.05) * (1.3 * (demo.trans / 0.05) * 0.05)) * 18)).toFixed(2)}</td>
                </tr>
              </tbody>
            </Table>
            <PayModal self="guide" />
            </>
          }
          {roi && pro === false && demo.industry === 'Home Business' &&
            <>
            <h6>The criteria for this illustrated projection are as follows: Based on the keyterms you entered, the average profit of ${demo.avgprof}, and the standard conversion rates for your industry, we estimate a profit projection as follows:</h6>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Package</th>
                  <th>Low Estimate</th>
                  <th>High Estimate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Standard (4 Term Virtual Platform)</td>
                  <td>${demo.avgprof * 7}</td>
                  <td>${demo.avgprof * 14}</td>
                </tr>
                <tr>
                  <td>Business Builder (8 Term Virtual Platform)</td>
                  <td>${demo.avgprof * 14}</td>
                  <td>${demo.avgprof * 28}</td>
                </tr>
                <tr>
                  <td>Elite (16 Term Virtual Platform)</td>
                  <td>${demo.avgprof * 28}</td>
                  <td>${demo.avgprof * 56}</td>
                </tr>
              </tbody>
            </Table>
            <PayModal self="guide" />
            </>
          }
          {roi && pro === false && demo.industry === 'Real Estate 2' && demo.avgprof < 10000 &&
            <>
            <h6>The criteria for this illustrated projection are as follows: Based on the keyterms you entered, the average profit of ${demo.avgprof}, and the standard conversion rates for your industry, we estimate a profit projection as follows:</h6>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Package</th>
                  <th>Low Estimate</th>
                  <th>High Estimate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Standard (4 Term Virtual Platform)</td>
                  <td>${demo.avgprof * 7}</td>
                  <td>${demo.avgprof * 14}</td>
                </tr>
                <tr>
                  <td>Business Builder (8 Term Virtual Platform)</td>
                  <td>${demo.avgprof * 14}</td>
                  <td>${demo.avgprof * 28}</td>
                </tr>
                <tr>
                  <td>Elite (16 Term Virtual Platform)</td>
                  <td>${demo.avgprof * 28}</td>
                  <td>${demo.avgprof * 56}</td>
                </tr>
              </tbody>
            </Table>
            <PayModal self="guide" />
            </>
          }
          {roi && pro === false && demo.industry === 'Real Estate 2' && demo.avgprof >= 10000 &&
          <>
          <h6>The criteria for this illustrated projection are as follows: Based on the keyterms you entered, the average profit of ${demo.avgprof}, and the standard conversion rates for your industry, we estimate a profit projection as follows:</h6>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Package</th>
                <th>Low Estimate</th>
                <th>High Estimate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Standard (4 Term Virtual Platform)</td>
                <td>${demo.avgprof * 4.5}</td>
                <td>${demo.avgprof * 9}</td>
              </tr>
              <tr>
                <td>Business Builder (8 Term Virtual Platform)</td>
                <td>${demo.avgprof * 9}</td>
                <td>${demo.avgprof * 18}</td>
              </tr>
              <tr>
                <td>Elite (16 Term Virtual Platform)</td>
                <td>${demo.avgprof * 18}</td>
                <td>${demo.avgprof * 36}</td>
              </tr>
            </tbody>
          </Table>
          <PayModal self="guide" />
          </>
        }
        </Form>
      </Modal.Body>
    </Modal>
    </>
  )
}
