import React, {Component} from 'react';
import {Carousel, Jumbotron} from 'react-bootstrap';
import ReactPlayer from 'react-player';
import RevCounter from './RevCounter';
import crowd from './images/crowd.png';
import hero from './images/hero.mp4';

class MainSlider extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render(){
    return(
      <>
      <ReactPlayer className="heroVid" url={hero} playing loop muted width="100%" height="80vh" />
      <div className="heroOver">
        <div className="col-lg-10 col-md-12 m-auto">
          <h2>ARE YOU LOSING BUSINESS FROM NOT BEING FOUND?</h2>
          <h4>ONLINE MARKETING IS CONSTANTLY EVOLVING</h4>
          <h4>CALL NOW TO SPEAK WITH ONE OF OUR MARKETING TACTICIANS</h4>
          <h4>AND LEARN HOW WE CAN HELP YOUR BUSINESS GROW!</h4>
        </div>
      </div>
      </>
    );
  }
}

export default MainSlider;
