import React, { Component } from 'react';
import { Button, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import moment from 'moment';
import ContactModal from './ContactModal';
import TestModal from './TestModal';
import AboutModal from './AboutModal';
import CredModal from './CredModal';
import ClientModal from './ClientModal';
import LoginModal from './LoginModal';
import MainSlider from './MainSlider';
import DemoModal2 from './DemoModal2';
import PayModalLink from './PayModalLink';
import ContactButton from './ContactButton';
import ProfitResults from './ProfitResults';
import ClientBackend from './ClientBackend';
import AdminModal from './AdminModal';
import CookieNotice from './CookieNotice';
import setStateAsync from './SetStateAsync';
import market from './images/market.png';
import fb from './images/fb.png';
import insta from './images/insta.png';
import yt from './images/yt.png';
import twit from './images/twit.png';
import goog from './images/google.png';
import aol from './images/aol.png';
import ebay from './images/ebay.png';
import bing from './images/bing.png';
import yahoo from './images/yahoo.png';
import logo from './images/newlogo.png';
import map from './images/map.png';
import OldDemo from './smdemo';
import Backup from './backupdemo';
import SaleBanner from './sale';
import NavDemo from './navdemo';
import './App.css';

class AuthenticationError extends Error {
  constructor(message, body) {
    super(message)
    this.body = body
  }
}
const initialState = {
  data: [],
  customers: {},
  admins: [],
  requests: [],
  isAdmin: false,
  customer: {},
  isLoggedIn: false,
  timestamp: '',
  ip: '',
  isDemo: false
}

class App extends Component {
  constructor(props){
    super(props);
    this.state = initialState

    this.loginCallback = this.loginCallback.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.checkAuthenticationSession = this.checkAuthenticationSession.bind(this);
    this.timeCallback = this.timeCallback.bind(this);
    this.setStateAsync = setStateAsync.bind(null, this);
    this.getCode = this.getCode.bind(this);
    // this.readCookie = this.readCookie.bind(this);
    // this.getCookie = this.getCookie.bind(this);
  }

  componentWillMount(){

  }

  componentDidMount() {
    this.checkAuthenticationSession()
    this.getCode()
  }

  getCode() {
    fetch('/getCode')
    .then(res => res.json()
    .then(body => {
      if (res.ok)
        this.setState({code: body[0].code});
      })
    )
  }

  checkAuthenticationSession() {
    /* Check login state */
    return fetch('/session', {method: 'POST'})
    .then(res => {
      if (res.ok)
        return res.json()
      return res.json()
      .then(body => {
        throw new AuthenticationError(`HTTP ${res.status}`, body)
      })
    })
    .then(sessionInfo => {
      /* TODO do not use username for this */
      const isAdmin = sessionInfo.username === 'admin' || sessionInfo.username === 'thefirstpagegroup'
      const isDemo = sessionInfo.username === 'demo' || sessionInfo.username === 'becomefound'
      const customer = {
        id: sessionInfo.id,
        company: sessionInfo.company,
        site: sessionInfo.site,
        contactname: sessionInfo.contactname,
        fullname: sessionInfo.fullname,
        emailaddress: sessionInfo.emailaddress,
        address: sessionInfo.address,
        city: sessionInfo.city,
        state: sessionInfo.state,
        zip: sessionInfo.zip,
        phone: sessionInfo.phone,
        consultant: sessionInfo.consultant,
        ordercode: sessionInfo.ordercode,
        package: sessionInfo.package,
        special: sessionInfo.special,
        date: sessionInfo.date,
        username: sessionInfo.username,
        isLoggedIn: sessionInfo.isLoggedIn,
        seoSite: sessionInfo.seoSite,
        hasTerms: sessionInfo.hasTerms,
        step: sessionInfo.step,
      }
      /* Subsequent steps require that these state mutations have completed.
       * TODO is there a better way? Will this result in more DOM renders Thank
       * we really need? */
      return this.setStateAsync({
        isLoggedIn: true,
        isAdmin,
        isDemo,
        customer,
      })
    })
    .catch(err => {
      console.info('authentication check failed:', err)
      if (err.body != null)
        console.info('authentication failure response body:', err.body)
      return this.setStateAsync({
        isLoggedIn: false,
        isAdmin: false,
        isDemo: false,
      })
    })
  }

//   readCookie(){
//     let i=0;
//     let user = this.getCookie("loginAs");
//     if (user === null){
//       this.setState({isLoggedIn:false, isAdmin:false, customer: {}});
//     } else {
//     for (i in this.state.customers){
//       if (this.state.customers[i].username === user){
//         this.setState({isLoggedIn:true, customer: this.state.customers[i]});
//         if (user === 'admin'){
//           this.setState({isAdmin:true});
//         }
//       }
//     }
//   }
// }
//
//   getCookie(name){
//     let cook = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
//     return cook ? cook[2] : null;
//   }

  loginCallback(){
    this.checkAuthenticationSession()
    .then(() =>
      this.state.isAdmin &&
      fetch('/customers')
      .then(res => {
        if (res.ok)
          return res.json()
        else
          throw new Error(`HTTP ${res.status}`)
      })
      .then(customers => this.setState({customers}))
    )
  }

  timeCallback(timestamp){
    this.setState({timestamp: timestamp});
  }

  handleLogout(){
    fetch('/logout', { method: 'POST' })
    .then(() => {
      this.setState(initialState)
    })
    .catch(err => {
      console.error('failed to logout:', err)
    })
  }

  render(){
    return (
      <div>
        <CookieNotice />
        <Navbar bg="dark" expand="lg">
          <Navbar.Brand href="/"><img src={logo} height="100" alt="The 1st Page Group Logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto" pullRight>
              {this.state.isLoggedIn && this.state.isDemo &&
                <>
                <ProfitResults customer={this.state.customer} isLoggedIn={this.state.isLoggedIn} timestamp={this.state.timestamp} ip={this.state.ip} />
                </>
              }
              {this.state.isLoggedIn === true && this.state.isAdmin === false &&
                <>
                <ClientBackend customer={this.state.customer} isLoggedIn={this.state.isLoggedIn} />
                </>
              }
              {this.state.isLoggedIn && this.state.isAdmin &&
                <AdminModal isAdmin={this.state.isAdmin} />
              }
              <ClientModal customer={this.state.customer} isLoggedIn={this.state.isLoggedIn} dCode={this.state.code} />
              <CredModal customer={this.state.customer} isLoggedIn={this.state.isLoggedIn} />
              <NavDemo dCode={this.state.code} />
              <PayModalLink self="guide" dCode={this.state.code} />
              <NavDropdown title="Connect With Us" id="basic-nav-dropdown">
                <NavDropdown.Item target="_blank" href="https://www.facebook.com/thefirstpagegroup"><img src={fb} width="35" alt="Facebook Logo" />Facebook</NavDropdown.Item>
                <NavDropdown.Item target="_blank" href="https://www.instagram.com/the1stpagegroup"><img src={insta} width="35" alt="Instagram Logo" />Instagram</NavDropdown.Item>
                <NavDropdown.Item target="_blank" href="https://www.youtube.com"><img src={yt} width="35" alt="YouTube Logo" />YouTube</NavDropdown.Item>
              </NavDropdown>
              <ContactModal customer={this.state.customer} isLoggedIn={this.state.isLoggedIn} />
              {this.state.isLoggedIn === false &&
                <LoginModal customers={this.state.customers} customer={this.state.customer} loginCallback={this.loginCallback} />
              }
              {this.state.isLoggedIn === true &&
                <Button variant="primary" onClick={this.handleLogout}>Logout</Button>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <MainSlider />
        {this.state.isLoggedIn === false &&
        <>
        <div className="container-fluid contentbox" id="boxtype3">
          <div className="container">
            <div className="row">
              <div id="phone" className="col-md-3 col-sm-12 text-center">
                <OldDemo dCode={this.state.code} />
              </div>
              <div className="col-md-9 col-sm-12">
                <h1>Become Found Online</h1>
                <p>A website that no one visits IS USELESS. A website with customized visitors who are looking for your services IS PRICELESS. Our Digital Marketing Programs enable our client's brand to be found online on many different platforms. Our digital marketing services will get your business found to increase your business's revenue.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid contentbox" id="boxtype2">
          <div className="container">
            <h3>Our full-service web solutions help your business grow online leads, calls, and revenue.</h3>
            <p>The First Page Group is a full-service Internet marketing, website design and organic lead generation company offering innovative web marketing solutions to small to large size companies across the world. As a leader in organic lead generation, web design, ecommerce, website conversion, and Internet marketing services, our firm prides ourselves on driving qualified buyers,
    converting visitors, and measuring effectiveness to ultimately deliver real results for our clients.</p>
            <p>We have an ever-expanding trophy cabinet for our web design, organic lead generation, and digital marketing work. Get in touch with our team today to experience what makes The First Page Group stand apart from other all other digital marketing agencies.</p>
          </div>
        </div>
        <div className="container-fluid contentbox" id="boxtype1">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <Backup dCode={this.state.code} />
              </div>
              <div id="centertext" className="col-md-6 col-sm-12">
                <h5>The Digital Business World is forever Evolving.</h5>
                <h4>If your business is not found by consumers who want your services,</h4>
                <h3>YOU'RE LOSING REVENUE</h3>
              </div>
              <div id="rightside" className="col-md-3 col-sm-12">
                <h6>Contact Us Today and Become Found Online</h6>
                <ContactButton customer={this.state.customer} isLoggedIn={this.state.isLoggedIn} />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid contentbox" id="boxtype2">
          <div className="container">
            <h3>We Are Proven to Get Results</h3>
            <p>We have helped businesses all throughout North America and in many different industries. Our goal is to help your business attract more clients so you can achieve a Growth in Revenue from being found online. We feel advertising should pay the business, not cost it. Let us turn your marketing dollars into a positive return.</p>
          </div>
        </div>
        </>
      }
      {this.state.isLoggedIn === true &&
        <>
        <div className="container" id="logos">
          <div className="row">
            <div className="col-4"><img src={goog} alt="Google Logo"/></div>
            <div className="col-4"><img src={bing} alt="Bing Logo" /></div>
            <div className="col-4"><img src={yahoo} alt="Yahoo Logo" /></div>
          </div>
          <div className="row">
            <div className="col-4"><img src={fb} alt="Facebook Logo" /></div>
            <div className="col-4"><img src={twit} alt="Twitter Logo" /></div>
            <div className="col-4"><img src={insta} alt="Instagram Logo" /></div>
          </div>
          <div className="row">
            <div className="col-4"><img src={yt} alt="YouTube Logo" /></div>
            <div className="col-4"><img src={ebay} alt="Ebay Logo" /></div>
            <div className="col-4"><img src={aol} alt="AOL Logo" /></div>
          </div>
        </div>
        <div className="container" id="gentext">
          <h5>We service clients from all over the world!</h5>
          <div className="row">
            <img src={map} alt="Our Service Area Map" id="map" />
          </div>
        </div>
        </>
      }
      <div className="container-fluid" id="footer">
        <div id="copyinfo">
          <ul>
            <li>&copy; The First Page Group</li>
          </ul>
        </div>
      </div>
      </div>
    );
  }
}

export default App;
