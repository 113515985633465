import React, {Component} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';
import AreaSelection from './AreaSelect'

class TermSelection extends Component {
  constructor(props, context){
    super(props,context);

    this.handleSelection = this.handleSelection.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      selectedTerms: [],
      submitted: false,
      fail: false
    }
  }

  handleSelection(event){
    let selects = this.state.selectedTerms;
    let j = 0;
    if (selects.includes(event.target.value)){
      for (j in selects){
        if (event.target.value === selects[j]){
          selects.splice(j, 1);
        }
      }
    } else {
      selects.push(event.target.value);
    }
    this.setState({selectedTerms: selects });
  }

  handleSubmit(){
    if (this.props.customer.package === 'Standard'){
      if (this.state.selectedTerms.length === 10){
        this.setState({submitted:true, fail:false})
      } else {
        this.setState({fail:true});
      }
    } else if (this.props.customer.package === 'Business Builder'){
      if (this.state.selectedTerms.length === 20){
        this.setState({submitted:true, fail:false})
      } else {
        this.setState({fail:true});
      }
    } else if (this.props.customer.package === 'Elite'){
      if (this.state.selectedTerms.length === 40){
        this.setState({submitted:true, fail:false})
      } else {
        this.setState({fail:true});
      }
    }
  }

  render(){
    return (
      <div>
      {this.props.customer.hasTerms === 'false' &&
        <>
        <h3>Select Your Keyterms</h3>
        <Alert variant="danger">This can only be done once. Make sure to double check the terms you have selected before submitting!</Alert>
        {this.props.customer.package === 'Standard' &&
          <Alert variant="primary">Select 6 Keyterms</Alert>
        }
        {this.props.customer.package === 'Business Builder' &&
          <Alert variant="primary">Select 12 Keyterms</Alert>
        }
        {this.props.customer.package === 'Elite' &&
          <Alert variant="primary">Select 24 Keyterms</Alert>
        }
        <Form>
        {this.state.submitted === false &&
          <>
          <Form.Label>Mark the Checkbox for each term you would like to use for your SEO Pages.</Form.Label>
          <Form.Group controlId="termChecks">
            <TermRender customer={this.props.customer} handleSelection={this.handleSelection} />
          </Form.Group>
          </>
        }
          {this.state.submitted === false &&
            <Button variant="primary" onClick={this.handleSubmit}>Submit Terms</Button>
          }
          {this.state.submitted === true &&
            <AreaSelection customer={this.props.customer} terms={this.state.selectedTerms} />
          }
          {this.state.fail &&
            <h6>Make sure you have selected the correct number of terms.</h6>
          }
        </Form>
        </>
      }
      {this.props.customer.hasTerms === 'true' &&
        <Alert variant="success">You have already selected your terms. Please contact support if there is a problem.</Alert>
      }
      </div>
    )
  }
}

class TermRender extends Component {
  constructor(props, context) {
    super(props, context);

    this.customerData = this.customerData.bind(this);

    this.state = {
      terms: [],
      videos:[]
    };
  }

  // <div className="col-lg-4">
  //   <img src={this.state.testimonials[i].image} alt={this.state.testimonials[i].name} width="150" height="150" />
  // </div>

  componentWillMount(){
    this.customerData();
  }

  customerData() {
    let j=0;
    fetch('/customerSuggest')
    .then(res => res.json()
      .then(body => {
        if (res.ok)
          for (j in body){
            if (body[j].fullname === this.props.customer.fullname && body[j].username === this.props.customer.username){
              this.setState({terms: body[j]});
            }
          }
        // throw new Error(body.err)
      })
    )
  }
    render(){
      let rows = [];
      let i = 0;
      for ( i in this.state.terms ){
        let currentKey = "this.state.terms." + i.toString();
        if (currentKey !== 'this.state.terms.key0' && this.state.terms[i] !== null && currentKey !== 'this.state.terms.username' && currentKey !== 'this.state.terms.fullname' && currentKey !== 'this.state.terms.package'){
          rows.push(
            <Form.Check type="checkbox" label={this.state.terms[i]} value={this.state.terms[i]} onChange={this.props.handleSelection} />
          )
        }
      }
      return(<>{rows}</>)
    }
  }


export default TermSelection;
