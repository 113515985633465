import React, {useState} from 'react';
import {Form, Button, Accordion, Card, Modal} from 'react-bootstrap';

export default function Neworder(){
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return(
    <>
    <Button variant="primary" onClick={handleShow}>Order Now</Button>

    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Order Today!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="orderform">
            <Form.Label>Business Name</Form.Label>
            <Form.Control type="text" />
            <hr />
            <Form.Label>Contact Name</Form.Label>
            <Form.Control type="text" />
            <hr />
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" />
            <hr />
            <Form.Label>Phone</Form.Label>
            <Form.Control type="text" />
            <hr />
            <Form.Label>Desired Username</Form.Label>
            <Form.Control type="text" />
            <hr />
            <Form.Label>Desired Password</Form.Label>
            <Form.Control type="text" />
            <hr />
            <Form.Label>Street Address</Form.Label>
            <Form.Control type="text" />
            <hr />
            <Form.Label>City</Form.Label>
            <Form.Control type="text" />
            <hr />
            <Form.Label>State</Form.Label>
            <Form.Control type="text" />
            <hr />
            <Form.Label>Zip Code</Form.Label>
            <Form.Control type="text" />
            <hr />
            <Form.Label>Select Package</Form.Label>
            <Form.Control as="select">
              <option>Choose One...</option>
              <option>Standard</option>
              <option>Elite</option>
            </Form.Control>
            <hr />
            <Form.Label>Additional Terms/Notes</Form.Label>
            <Form.Control as="textarea" rows="3" />
            <hr />
            <Accordion>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Terms and Conditions
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <ul>
                      <li><strong>I understand and agree to the following:</strong></li>
                      <li>The payment method I have entered will be charged for the amount I have entered and understand that this is custom work.</li>
                      <li>We are providing you with a brand new website to promote your business and products through the world wide web and we do not guarantee or imply any amount of leads or sales that will be produced from this custom website / content.</li>
                      <li>This agreed to transaction will be for developing a custom website as per my order and I understand that due to the specific design and content of my new website I agree to a no refund no cancellation process.</li>
                      <li>The custom design and content of the custom website I am ordering will be determined on the website review call.</li>
                      <li>I understand and agree to a $15 per month maintenance fee.</li>
                      <li>Per this order there will be 2 revisions allowed after the review and page selection communication session and I agree that because of the custom design and content that the work can only be utilized on one product / business and can’t be returned or refunded.</li>
                      <li>Your electronic acceptance of this agreement signifies that you have read and agree to the above terms and conditions.</li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <hr />
            <Form.Label>Signature</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
          <Button variant="success" type="submit">Complete Purchase</Button>
        </Form>
      </Modal.Body>
    </Modal>
    </>
  )
}
