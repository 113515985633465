import React, {Component} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';
import SelectSuccess from './SelectionSuccess';

class ClientSelect extends Component {
  constructor(props, context){
    super(props,context);

    this.handleSelection = this.handleSelection.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      selectedTerms: {},
      submitted: false,
      fail: false,
      areas: []
    }
  }

  handleSelection(e){
    let stateResetter = this.state.selectedTerms;
    stateResetter[e.target.name] = e.target.value;
    this.setState({selectedTerms: stateResetter})
    console.log("Etargetname " + e.target.name);
    console.log("Etargetvalue " + e.target.value);
    console.log("Result for " + e.target.name + ": " + this.state.selectedTerms[e.target.name]);
  }

  handleSubmit(){
    const pkg = {
      username: this.props.customer.username,
      fullname: this.props.customer.fullname,
      level: this.props.customer.package,
      terms: this.state.selectedTerms,
      hasTerms: 'true'
    }
    fetch('/termsUpdate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(pkg)
    })
    .then(() => {
      this.setState({submitted: true});
    });
  }

  render(){
    return(
      <>
      <Alert variant="danger">This can only be done once. Make sure to double check the terms you have selected before submitting!</Alert>
      <DropRender customer={this.props.customer} handleSelection={this.handleSelection} selectedTerms={this.state.selectedTerms} />
      <hr />
      <Alert variant="success">These are the areas our research has determined to be most effective for your marketing.</Alert>
      <ul id="areas">
        <AreaRender customer={this.props.customer} />
      </ul>
      {this.state.submitted === false &&
        <Button variant="success" type="submit" onClick={this.handleSubmit}>Submit Selections</Button>
      }
      {this.state.submitted === true &&
        <SelectSuccess />
      }
      </>
    )
  }
}

class AreaRender extends Component {
  constructor(props, context){
    super(props, context);

    this.customerAreas = this.customerAreas.bind(this);

    this.state = {
      areas: []
    }
  }

  componentWillMount(){
    this.customerAreas();
  }

  customerAreas(){
    let f=0;
    fetch('/customerAreas')
    .then(res => res.json()
      .then(body => {
        if (res.ok)
          for (f in body){
            if (body[f].fullname == this.props.customer.fullname && body[f].username === this.props.customer.username){
              this.setState({areas: body[f]});
            }
          }
      })
    )
  }
  render(){
    let arearows = [];
    let a = 0;
    for (a in this.state.areas){
      if (this.state.areas[a] !== null && this.state.areas[a] !== this.props.customer.username && this.state.areas[a] !== this.props.customer.package && this.state.areas[a] !== this.props.customer.fullname){
        arearows.push(
          <li>{this.state.areas[a]}</li>
        )
      }
    }
    return(<>{arearows}</>)
  }
}

class DropRender extends Component {
  constructor(props, context){
    super(props, context);

    this.customerData = this.customerData.bind(this);

    this.state = {
      terms: []
    }
  }

  componentWillMount(){
    this.customerData();
  }

  customerData(){
    let j=0;
    fetch('/customerSuggest')
    .then(res => res.json()
      .then(body => {
        if (res.ok)
          for (j in body){
            if (body[j].fullname == this.props.customer.fullname && body[j].username === this.props.customer.username){
              this.setState({terms: body[j]});
            }
          }
      })
    )
  }
  render(){
    let rows = [];
    let x=1;
    let total = 1;
    console.log(this.props.customer.package);
    if (this.props.customer.package == 'Standard'){
      total = 12;
    } else if (this.props.customer.package == 'Business Builder'){
      total = 24;
    } else if (this.props.customer.package == 'Elite'){
      total = 48;
    } else if (this.props.customer.package == 'Starter'){
      total = 6;
    }
    while(x <= total){
      let currentKey = "key" + x.toString();
      // let y = x.match(/(\d+)/);
      if (this.state.terms[x] !== null && currentKey !== 'this.state.terms.username' && currentKey !== 'this.state.terms.fullname' && currentKey !== 'this.state.terms.package'){
        rows.push(
          <>
          <Form.Label>Keyterm {x}</Form.Label>
          <Form.Control as="select" name={currentKey} onChange={this.props.handleSelection} value={this.props.selectedTerms[currentKey]}>
            <option>Select One:</option>
            <option>{this.props.selectedTerms[currentKey]}</option>
            <OptionRender customer={this.props.customer} terms={this.state.terms} selectedTerms={this.props.selectedTerms} />
          </Form.Control>
          </>
        )
      }
      x = x + 1;
    }
    return(<>{rows}</>)
  }
}

class OptionRender extends Component {
  render(){
    let termrows = [];
    let i = 0;
    for (i in this.props.terms){
      if (this.props.terms[i] !== null && this.props.terms[i] !== this.props.customer.username && this.props.terms[i] !== this.props.customer.fullname && this.props.terms[i] !== this.props.customer.package && !Object.values(this.props.selectedTerms).includes(this.props.terms[i])){
        termrows.push(
          <option>{this.props.terms[i]}</option>
        )
      }
    }
    return(<>{termrows}</>)
  }
}

export default ClientSelect;
