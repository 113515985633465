import React, {Component} from 'react';
import {Button, Modal, Nav} from 'react-bootstrap';
import office from './images/office.jpg';

class SiteModal extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            showContent: false,
            showLogin: false
        };
    }

    handleClose() {
        this.setState({ showContent: false, showLogin:false });
    }

    handleShow() {
      this.setState({showContent:true});
      // if (this.props.isLoggedIn === true){
      //   this.setState({ showContent: true });
      // } else {
      //   this.setState({showLogin:true});
      // }
    }

    render(){
        return (
        <>
        <Nav.Link onClick={this.handleShow}>What We Do</Nav.Link>
        <Modal size="xl" show={this.state.showContent} onHide={this.handleClose} backdrop={false}>
            <Modal.Header closeButton>
                <Modal.Title>What We Do</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container" id="imgbox">
                <img src={office} alt="The First Page Group Office" id="aboutimg" />
              </div>
              <div className="container-fluid contentbox">
                <h3>Become Found Online with The 1st Page Group</h3>
                <p>The 1st Page Group knows how important it is to remove barriers and make it easy for your customers to find and contact you. Today's consumer expects instant results so we design visually appealing websites aimed straight at your target audience to increase traffic and lead generation. Whatever your specific needs are we can make it happen.</p>
                <p>The best website on the internet will generate zero results if it isn't easy to find. If you want to make the sale, you need to put your website in front of customers who are ready to buy. Research has shown that as much as 90 percent of all internet sales are generated from people who are ready to buy before they know where they want to buy it from. The best way to get the sale is to be the first user-friendly page they find.</p>
                <p>This is where The 1st Page Group comes in. We will create custom content geared specifically for your business and integrate into a website that mirrors your current site. By taking advantage of the latest techniques for optimizing search engine results you will <strong>Become Found Online</strong>. We will help you attract your target market to your website. Because we develop add on pages that redirects traffic to you, we don't have to work on your current page. We will never ask you for your FPT information, passwords or other login information and we don't need to make any changes to your current home page.</p>
                <p>Your custom campaign website includes all of the technology, design work, and original content. We also provide hosting on our own servers. This is a complete package, priced based on the amount of content you purchase.</p>
              </div>
            </Modal.Body>
          <Modal.Footer><Button variant="secondary" onClick={this.handleClose}>Close</Button></Modal.Footer>
        </Modal>
        </>
          );
      }
  }

export default SiteModal;
