import React, {Component} from 'react';
import {Button, Modal, Nav, Form, Image} from 'react-bootstrap';
import leo from './images/burg.png';
import soth from './images/soth.png';
import fb from './images/fb.png';
import duck from './images/duck.png';
import brave from './images/brave.png';
import insta from './images/insta.png';
import yt from './images/yt.png';
import twit from './images/xlogo.png';
import goog from './images/google.png';
import aol from './images/reddit.png';
import ebay from './images/linked.webp';
import bing from './images/bing.png';
import yahoo from './images/yahoo.png';

class ClientModal extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        this.state = {
        	showContent: false,
        	showLogin: false,
          codeCheck: ''
        };
    }

    handleClose() {
        this.setState({ showContent: false, showLogin:false });
    }

    handleShow() {
      this.setState({showContent:true});
      // if (this.props.isLoggedIn === true){
      //   this.setState({ showContent: true });
      // } else {
      //   this.setState({showLogin:true});
      // }
    }

    handleCheck(e){
      this.setState({codeCheck: e.target.value});
    }

    render(){
        return (
        <>
        <Nav.Link onClick={this.handleShow}>Websites</Nav.Link>
        <Modal size="xl" show={this.state.showContent} onHide={this.handleClose} backdrop={false}>
            <Modal.Body>
              <div className="container mheadline">
                <h3>Learn How to Generate Sellers and Buyers Organically Across All Major Platforms and Receive Buyers Directly from Our Database</h3>
              </div>
              <div className="container imgrack2">
                <div className="row">
                  <div className="col-4"><img src={goog} alt="Google Logo" /></div>
                  <div className="col-4"><img src={bing} alt="Bing Logo" /></div>
                  <div className="col-4"><img src={yahoo} alt="Yahoo Logo" /></div>
                </div>
                <div className="row">
                  <div className="col-4"><img src={duck} alt="DuckDuckGo Logo" /></div>
                  <div className="col-4"><img src={brave} alt="Brave Logo" /></div>
                  <div className="col-4"><img src={twit} alt="Twitter/X Logo" /></div>
                </div>
                <div className="row">
                  <div className="col-4"><img src={yt} alt="YouTube Logo" /></div>
                  <div className="col-4"><img src={ebay} alt="Linked In Logo" /></div>
                  <div className="col-4"><img src={aol} alt="Reddit Logo" /></div>
                </div>
              </div>
              <div className="container" id="clientimgs">
                <div className="row">
                  <div className="col-12">
                    <a href="https://www.google.com/search?q=sell+my+home+hockinson+heights" target="_blank" rel="noopener noreferrer"><Image src={leo} alt="Tracyburgesssells.com" rounded /></a>
                    <h5>tracyburgesssells.com</h5>
                  </div>
                </div>
              </div>
            </Modal.Body>
          <Modal.Footer><p className="tiny">This information is presented for illustrative purposes.</p><Button variant="secondary" onClick={this.handleClose}>Close</Button></Modal.Footer>
        </Modal>
        </>
          );
      }
  }

export default ClientModal;
