import React, {Component} from 'react';
import {Button, Modal, Nav, Tabs, Tab} from 'react-bootstrap';
import sponsors from './images/sponsorship.png';
import creds from './images/support.png';
import cards from './images/credit.png';
import visa from './images/visa.png';
import master from './images/master.png';
import amex from './images/amex.png';
import discover from './images/discover.png';
import test1 from './images/victoria.jpg';
import test2 from './images/peter.jpg';
import test3 from './images/leo.jpg';
import test4 from './images/erica.jpg';
import test5 from './images/maryv.jpg';
import test6 from './images/eugene.jpg';
import review from './images/newcred2.png';


class CredModal extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
        	showContent: false,
        	showLogin: false
        };
    }

    handleClose() {
        this.setState({ showContent: false, showLogin:false });
    }

    handleShow() {
      this.setState({showContent:true});
      // if (this.props.isLoggedIn === true){
    	//    this.setState({ showContent: true });
      // } else {
    	//    this.setState({showLogin:true});
      // }
    }

    render(){
        return (
        <>
        <Nav.Link onClick={this.handleShow}>Credentials</Nav.Link>
        <Modal size="xl" show={this.state.showContent} onHide={this.handleClose} backdrop={false}>
            <Modal.Header closeButton>
                <Modal.Title>The 1st Page Group Credentials</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container imgrack2 cardrack">
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-8 usaf"><img src={review} alt="Google Reviews" className="w-100" /></div>
                  <div className="col-2"></div>
                </div>
              </div>
              <div className="container testboxl">
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-2"><img src={test1} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-8">
                    <h3>Veronica G.</h3>
                    <p>As a new business owner it can be difficult to navigate the advertising waters. We didn't know if we should do print or social media or paid ads on google. So glad that the 1st Pg Group call us and offered us this opportunity to become found online with out having to pay per click. We get calls, emails, and inquiries literally all hours of the day and night and business is booming. Thanks</p>
                  </div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-8">
                    <h3>Tim B.</h3>
                    <p>My Solar panel installations have been up by 60% this month! Has been a great experience working with everyone at first page, staff has been very helpful, glad to be on board business is booming!</p>
                  </div>
                  <div className="col-2"><img src={test2} className="rounded-circle w-100" alt="Review Poster" /></div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-2"><img src={test3} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-8">
                    <h3>Tommy D.</h3>
                    <p>So glad I decided to work with first page group, I was struggling to get people to my site that were qualified and ready and able to purchase. There customer service has been second to none, and you can call and speak with your actual rep that's in the U.S. Second month in a business is booming!</p>
                  </div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-8">
                    <h3>Bonnie P.</h3>
                    <p>First impression was the consultant seemed a little pushy which is why I only leave 4 stars but I'm 2 months in and have gotten several new accounts so I'm glad I decided to work with them.</p>
                  </div>
                  <div className="col-2"><img src={test4} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-2"><img src={test5} className="rounded-circle w-100" alt="Review Poster" /></div>
                  <div className="col-8">
                    <h3>Mary V.</h3>
                    <p>Love the service, I was working from home part-time and wasn't able to get my business off the ground until they helped me bring in enough buyers to now work from home full time I have more time for my family now. Such a great service.</p>
                  </div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-8">
                    <h3>Bob G.</h3>
                    <p>I have always been hesitant to advertise but the group has been an excellent decision for my Insurance agency I will recommend this to any insurance agent. Thanks for the stellar website and service!</p>
                  </div>
                  <div className="col-2"><img src={test6} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-1"></div>
                </div>
              </div>
            </Modal.Body>
          <Modal.Footer><Button variant="secondary" onClick={this.handleClose}>Close</Button></Modal.Footer>
        </Modal>
        </>
          );
      }
  }

export default CredModal;
