import React, {Component} from 'react';
import {Button, Modal, Nav, Tabs, Tab} from 'react-bootstrap';
import CustomerProfile from './CustomerProfile';
import ClientTerms from './ClientTerms';
import ContactSupport from './ContactSupport';
import TermSelection from './TermSelection';
import ClientSelect from './ClientSelect';

class ClientBackend extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
        	showContent: false,
        	showLogin: false
        };
    }

    handleClose() {
        this.setState({ showContent: false, showLogin:false });
    }

    handleShow() {
      if (this.props.isLoggedIn === true){
        this.setState({ showContent: true });
      } else {
        this.setState({showLogin:true});
      }
    }

    render(){
        return (
        <>
        <Nav.Link onClick={this.handleShow}>My Account</Nav.Link>
        <Modal size="xl" show={this.state.showContent} onHide={this.handleClose} backdrop={false}>
            <Modal.Header closeButton>
                <Modal.Title>Welcome Back, {this.props.customer.username}!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs defaultActiveKey="profile" id="customerTabs">
                <Tab eventKey="profile" title="My Profile">
                  <CustomerProfile customer={this.props.customer} />
                </Tab>
                {this.props.customer.hasTerms !== 'true' &&
                  <Tab eventKey="termselect" title="Keyterm Selector">
                    <ClientSelect customer={this.props.customer} />
                  </Tab>
                }
                {this.props.customer.hasTerms === 'true' &&
                  <Tab eventKey="rankings" title="My Keyterms">
                    <ClientTerms customer={this.props.customer} />
                  </Tab>
                }
                <Tab eventKey="support" title="Get Help">
                  <ContactSupport customer={this.props.customer} />
                </Tab>
              </Tabs>
            </Modal.Body>
          <Modal.Footer><Button variant="secondary" onClick={this.handleClose}>Close</Button></Modal.Footer>
        </Modal>
        <Modal size="lg" show={this.state.showLogin} onHide={this.handleClose}>
        	<Modal.Header closeButton>
        		<Modal.Title>Please Login to Your Account</Modal.Title>
        	</Modal.Header>
        	<Modal.Body>
        	  <div className="container-fluid contentbox">
        		<p>Please use the Login button to access your account first.</p>
        	  </div>
        	</Modal.Body>
          <Modal.Footer><Button id="clientClose" variant="secondary" onClick={this.handleClose}>Close</Button></Modal.Footer>
        </Modal>
        </>
          );
      }
  }

export default ClientBackend;
