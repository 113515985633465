import React, {Component} from 'react';
import {Button, Modal, Nav} from 'react-bootstrap';

class TestModal extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            showContent: false,
            showLogin: false
        };
    }

    handleClose() {
        this.setState({ showContent: false, showLogin:false });
    }

    handleShow() {
      this.setState({showContent: true});
      // if (this.props.isLoggedIn === true){
      //   this.setState({ showContent: true });
      // } else {
      //   this.setState({showLogin:true});
      // }
    }

    render(){
        return (
        <>
        <Nav.Link onClick={this.handleShow}>Testimonials</Nav.Link>
        <Modal size="xl" show={this.state.showContent} onHide={this.handleClose} backdrop={false}>
            <Modal.Header closeButton>
                <Modal.Title>What Our Clients Say</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <TestRender />
                </div>
              </div>
            </Modal.Body>
          <Modal.Footer><Button variant="secondary" onClick={this.handleClose}>Close</Button></Modal.Footer>
        </Modal>
        </>
          );
      }
  }

  class TestRender extends Component {
    constructor(props, context) {
      super(props, context);

      this.testData = this.testData.bind(this);

      this.state = {
        testimonials: []
      };
    }

    // <div className="col-lg-4">
    //   <img src={this.state.testimonials[i].image} alt={this.state.testimonials[i].name} width="150" height="150" />
    // </div>

    componentWillMount(){
      this.testData();
    }

    testData() {
      fetch('/testimonials')
        .then(res => res.json()
         .then(body => {
           if (res.ok)
            this.setState({testimonials: body});
         })
      )
    }

    render(){
      let rows = [];
      let i = 0;
      for ( i in this.state.testimonials ){
        rows.push(
          <>
          <div className="container">
            <div className="row" id="testbox">
              <div className="col-12">
                <h4>{this.state.testimonials[i].name}</h4>
                <p>{this.state.testimonials[i].message}</p>
              </div>
            </div>
          </div>
          </>
        )
      }
      return(<>{rows}</>);
    }
  }

export default TestModal;
