import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import goog from './images/google.png';
import bing from './images/bing.png';
import yahoo from './images/yahoo.png';

class SearchResult extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false
        };
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
      document.getElementById('layer3').style.display = 'none';
      this.setState({ show: true });
    }

    render(){
        return (
        <>
        <Button onClick={this.handleShow}>See Your Results!</Button>
        <Modal size="xl" show={this.state.show} onHide={this.handleClose} id="layer4" backdrop={false}>
            <Modal.Header closeButton>
                <Modal.Title>Your Search Results</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container" id="keybox">
                <h4>Click a Logo to see your Keyterm on that Engine!</h4>
                <div className="keytermbox col-12">
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://www.google.com/search?source=ig&hl=en&rlz=&q=" + this.props.key1 + "&btnG=Google+Search&start=00&nfpr=1"} target="_blank" rel="noopener noreferrer"><img src={goog} alt="Google Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key1}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>8,933 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>172,420 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>7,100 Average Searches</h6>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://www.bing.com/search?q=" + this.props.key1 + "&qs=n&form=QBLH&sc=2-13&sp=-1&sk=1"} target="_blank" rel="noopener noreferrer"><img src={bing} alt="Bing Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key1}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>3,321 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>99,165 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>3,650 Average Searches</h6>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://search.yahoo.com/search;_ylt=A0oGdWif4ItRy1kA3AZXNyoA?p=" + this.props.key1 + "&fr2=sb-top&fr=yfp-t-501"} target="_blank" rel="noopener noreferrer"><img src={yahoo} alt="Yahoo Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key1}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>4,770 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>64,100 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>2,977 Average Searches</h6>
                      }
                    </div>
                  </div>
                </div>
                <div className="keytermbox col-12">
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://www.google.com/search?source=ig&hl=en&rlz=&q=" + this.props.key2 + "&btnG=Google+Search&start=00&nfpr=1"} target="_blank" rel="noopener noreferrer"><img src={goog} alt="Google Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key2}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>3,920 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>74,780 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>3,960 Average Searches</h6>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://www.bing.com/search?q=" + this.props.key2 + "&qs=n&form=QBLH&sc=2-13&sp=-1&sk=1"} target="_blank" rel="noopener noreferrer"><img src={bing} alt="Bing Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key2}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>1,640 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>36,700 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>3,330 Average Searches</h6>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://search.yahoo.com/search;_ylt=A0oGdWif4ItRy1kA3AZXNyoA?p=" + this.props.key2 + "&fr2=sb-top&fr=yfp-t-501"} target="_blank" rel="noopener noreferrer"><img src={yahoo} alt="Yahoo Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key2}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>1,986 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>21,365 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>1,785 Average Searches</h6>
                      }
                    </div>
                  </div>
                </div>
                <div className="keytermbox col-12">
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://www.google.com/search?source=ig&hl=en&rlz=&q=" + this.props.key3 + "&btnG=Google+Search&start=00&nfpr=1"} target="_blank" rel="noopener noreferrer"><img src={goog} alt="Google Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key3}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>4,150 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>218,170 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>5,380 Average Searches</h6>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://www.bing.com/search?q=" + this.props.key3 + "&qs=n&form=QBLH&sc=2-13&sp=-1&sk=1"} target="_blank" rel="noopener noreferrer"><img src={bing} alt="Bing Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key3}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>1,985 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>99,100 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>2,795 Average Searches</h6>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://search.yahoo.com/search;_ylt=A0oGdWif4ItRy1kA3AZXNyoA?p=" + this.props.key3 + "&fr2=sb-top&fr=yfp-t-501"} target="_blank" rel="noopener noreferrer"><img src={yahoo} alt="Yahoo Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key3}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>1,450 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>166,720 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>3,690 Average Searches</h6>
                      }
                    </div>
                  </div>
                </div>
                <div className="keytermbox col-12">
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://www.google.com/search?source=ig&hl=en&rlz=&q=" + this.props.key4 + "&btnG=Google+Search&start=00&nfpr=1"} target="_blank" rel="noopener noreferrer"><img src={goog} alt="Google Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key4}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>5,320 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>121,320 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>2,782 Average Searches</h6>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://www.bing.com/search?q=" + this.props.key4 + "&qs=n&form=QBLH&sc=2-13&sp=-1&sk=1"} target="_blank" rel="noopener noreferrer"><img src={bing} alt="Bing Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key4}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>2,980 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>69,430 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>1,719 Average Searches</h6>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://search.yahoo.com/search;_ylt=A0oGdWif4ItRy1kA3AZXNyoA?p=" + this.props.key4 + "&fr2=sb-top&fr=yfp-t-501"} target="_blank" rel="noopener noreferrer"><img src={yahoo} alt="Yahoo Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key4}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>1,995 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>87,420 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>1,121 Average Searches</h6>
                      }
                    </div>
                  </div>
                </div>
                <div className="keytermbox col-12">
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://www.google.com/search?source=ig&hl=en&rlz=&q=" + this.props.key5 + "&btnG=Google+Search&start=00&nfpr=1"} target="_blank" rel="noopener noreferrer"><img src={goog} alt="Google Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key5}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>2,100 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>211,300 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>6,995 Average Searches</h6>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://www.bing.com/search?q=" + this.props.key5 + "&qs=n&form=QBLH&sc=2-13&sp=-1&sk=1"} target="_blank" rel="noopener noreferrer"><img src={bing} alt="Bing Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key5}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>1,150 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>112,960 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>4,795 Average Searches</h6>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://search.yahoo.com/search;_ylt=A0oGdWif4ItRy1kA3AZXNyoA?p=" + this.props.key5 + "&fr2=sb-top&fr=yfp-t-501"} target="_blank" rel="noopener noreferrer"><img src={yahoo} alt="Yahoo Logo" height="75" /></a>
                    </div>
                    <div className="col-6">
                      <h5>{this.props.key5}</h5>
                      {this.props.industry === 'Real Estate' &&
                        <h6>580 Average Searches</h6>
                      }
                      {this.props.industry === 'Home Based Business' &&
                        <h6>89,333 Average Searches</h6>
                      }
                      {this.props.industry === 'Other' &&
                        <h6>2,995 Average Searches</h6>
                      }
                    </div>
                  </div>
                </div>
                <p className="tiny">This information is presented for illustrative purposes.</p>
              </div>
              <Button variant="success" onClick={this.handleClose}>Close this Window</Button>
            </Modal.Body>
        </Modal>
        </>
          );
      }
  }

export default SearchResult;
