import React, {Component} from 'react';
import {Button, Modal, Accordion, Card} from 'react-bootstrap';
import Neworder from './NewOrder';

class ProfitResults extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false,
            keyterms: {},
            entry: {}
        };
    }

    componentWillMount(){
      this.keytermData();
    }

    keytermData(){
      let i = 0;
      fetch('/keyterms')
        .then(res => res.json()
          .then(body => {
            if (res.ok)
              if(body[0] !== null && body[0] !== undefined && body[0] !== [] && body[0] !== {}) {
                this.setState({keyterms: body[0]});
                if (isNaN(parseInt(body[0].avgsale)) === true){
                  this.setState({keyterms: {avgsale: 2999}});
                }
              } else {
                this.setState({keyterms: {avgsale: 2999}});
              }
            }
          )
        )
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
      this.setState({ show: true });
    }

    render(){
      return (
        <>
        <Button onClick={this.handleShow}>See Our Packages</Button>
        <Modal size="lg" show={this.state.show} onHide={this.handleClose} id="layer5" backdrop={false}>
            <Modal.Header closeButton>
                <Modal.Title>The First Page Group Packages</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Accordion defaultActiveKey="1">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      Standard Package
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <ul>
                        <li>Landing Page, plus 10 Content Pages</li>
                        <li>Dedicated Social Media Helper</li>
                        <li>Templated Site Design</li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      Elite Package
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <ul>
                        <li>Landing Page, plus 20 Content Pages</li>
                        <li>Dedicated Social Media Helper</li>
                        <li>Custom Site Design</li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Neworder />
            </Modal.Body>
        </Modal>
        </>
        );}
      }

export default ProfitResults;
