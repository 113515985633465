import React, {Component} from 'react';
import {Button, Modal, Alert} from 'react-bootstrap';
import CookieModal from './CookieModal';

class CookieNotice extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.cookieHandler = this.cookieHandler.bind(this);
        this.getCookie = this.getCookie.bind(this);

        this.state = {
        	showContent: true,
        	showLogin: false
        };
    }

    componentWillMount(){
      let cook = this.getCookie("acceptsCookies");
      if (cook === "true"){
        this.setState({showContent: false, showLogin:true});
      }
    }

    getCookie(name){
      let cook = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      return cook ? cook[2] : null;
    }

    handleClose() {
        this.setState({ showContent: false, showLogin:false });
    }

    handleShow() {
      this.setState({showContent:true});
    }

    cookieHandler(){
      let twoWeeks = new Date(Date.now() + 12096e5);
      document.cookie = "acceptsCookies=true;expires=" + twoWeeks.toUTCString();
      this.setState({showContent: false});
    }

    render(){
        return (
        <>
        <Modal show={this.state.showContent} onHide={this.handleClose} backdrop={false}>
            <Modal.Header closeButton>
                <Modal.Title>Notice: We Use Cookies</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant="warning">Just like nearly every other website, we use cookies. If you want to know what we do with them, click this button:</Alert>
              <CookieModal />
            </Modal.Body>
            <Modal.Footer>
              <a href="https://www.google.com">Leave</a>
              <Button variant="success" onClick={this.cookieHandler}>Accept</Button>
            </Modal.Footer>
        </Modal>
        </>
          )
        }
      }
export default CookieNotice;
