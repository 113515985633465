import React, {Component} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';

class ContactButton extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleBusiness = this.handleBusiness.bind(this);
        this.handlePhoneNum = this.handlePhoneNum.bind(this);
        this.handleNewEmail = this.handleNewEmail.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.handleContactMethod = this.handleContactMethod.bind(this);
        this.handleTime = this.handleTime.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            show: false,
            yourName: '',
            businessname: '',
            phonenum: '',
            email: '',
            message: '',
            method: '',
            time: '',
            submitted: false
        };
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleNameChange(event){
      this.setState({yourName: event.target.value});
    }

    handleBusiness(event){
      this.setState({businessname: event.target.value});
    }

    handlePhoneNum(event){
      this.setState({phonenum: event.target.value});
    }

    handleNewEmail(event){
      this.setState({email: event.target.value});
    }

    handleMessage(event){
      this.setState({message: event.target.value});
    }

    handleContactMethod(event){
      this.setState({method: event.target.value});
    }

    handleTime(event){
      this.setState({time: event.target.value});
    }

    handleSubmit(){
      const pkg = {
        name: this.state.yourName,
        business: this.state.businessname,
        phone: this.state.phonenum,
        email: this.state.email,
        message: this.state.message,
        method: this.state.method,
        time: this.state.time
      }
      fetch('/sendEmail', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
      })
      .then(
        this.setState({submitted: true})
      )
    }

    render(){
        return (
        <>
        <Button onClick={this.handleShow}>Request Free Demo</Button>
        <Modal size="xl" show={this.state.show} onHide={this.handleClose} backdrop={false}>
            <Modal.Header closeButton>
                <Modal.Title>Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul>
                <li><h5><a href="tel:8335367192">Phone: (833)536-7192</a></h5></li>
              </ul>
              <Form>
                <Form.Group controlId="formRequestDemo">
                  <Form.Label>Your Name</Form.Label>
                  <Form.Control type="text" onChange={this.handleNameChange} value={this.state.yourName} />
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control type="text" onChange={this.handleBusiness} value={this.state.businessname} />
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" onChange={this.handlePhoneNum} value={this.state.phonenum} />
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" onChange={this.handleNewEmail} value={this.state.email} />
                  <Form.Label>Message</Form.Label>
                  <Form.Control type="textarea" rows="3" onChange={this.handleMessage} value={this.state.message} />
                  <Form.Label>Method of Contact</Form.Label>
                  <Form.Control as="select" onChange={this.handleContactMethod} value={this.state.method}>
                    <option>Choose...</option>
                    <option>Call</option>
                    <option>Text</option>
                    <option>Email</option>
                  </Form.Control>
                  <Form.Label>Best Time to Reach You</Form.Label>
                  <Form.Control as="select" onChange={this.handleTime} value={this.state.time}>
                    <option>Choose...</option>
                    <option>Mornings (8 AM to Noon)</option>
                    <option>Afternoons (Noon to 5 PM)</option>
                    <option>Evening (5 PM to 9 PM)</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </Modal.Body>
          <Modal.Footer>
            {this.state.submitted === false &&
              <Button variant="primary" onClick={this.handleSubmit}>Send!</Button>
            }
            {this.state.submitted &&
              <>
                <p>Message Sent!</p>
                <Button variant="success" onClick={this.handleClose}>Close</Button>
              </>
            }
          </Modal.Footer>
        </Modal>
        </>
          );
      }
  }

export default ContactButton;
