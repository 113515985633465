import React, {Component} from 'react';
import {Button, Modal, Badge} from 'react-bootstrap';

class TermDisplay extends Component {
  constructor(props, context) {
      super(props, context);

      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);

      this.state = {
          showContent: false,
          showLogin: false
      };
  }

  handleClose() {
      this.setState({ showContent: false, showLogin:false });
  }

  handleShow() {
    this.setState({showContent:true});
  }

  render(){
    return (
    <>
    <Button size="sm" onClick={this.handleShow}>Show Text</Button>
    <Modal size="xl" show={this.state.showContent} onHide={this.handleClose} backdrop={false}>
        <Modal.Header closeButton>
            <Modal.Title>
            {this.props.term}
            {this.props.source === 'Ours' && <Badge variant='success'>1stPage</Badge>}
            {this.props.source === 'Theirs' && <Badge variant='warning'>Other</Badge>}
            {this.props.usage === 'Yes' && <Badge variant='danger'>In Use</Badge>}
            {this.props.usage === 'No' && <Badge variant='info'>Unused</Badge>}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid contentbox">
            <p>{this.props.text}</p>
          </div>
        </Modal.Body>
      <Modal.Footer><Button variant="secondary" onClick={this.handleClose}>Close</Button></Modal.Footer>
    </Modal>
    </>
      );
  }
}

export default TermDisplay;
