import React, {useState, useEffect} from 'react';
import {Modal, Button, Form, Table, Accordion, Card} from 'react-bootstrap';
import ReactPlayer from 'react-player';
import PayModal from './PaymentModal';
import logo from './images/logo.png';
import leo from './images/leo.png';
import fb from './images/fb.png';
import twit from './images/twit.png';
import insta from './images/insta.png';
import google from './images/google.png';
import yahoo from './images/yahoo.png';
import bing from './images/bing.png';
import aol from './images/reddit.png';
import ebay from './images/linked.webp';
import yt from './images/yt.png';
import chart from './images/metrics.png';
import idx from './images/idx.png';
import phone from './images/phone.png';
import funnel from './images/funnel.png';
import impress from './images/impress.png';
import yes from './images/yes.png';
import no from './images/no.png';
import creds from './images/support.png';
import cards from './images/credit.png';
import visa from './images/visa.png';
import master from './images/master.png';
import amex from './images/amex.png';
import discover from './images/discover.png';
import bulb from './images/market.png';
import duck from './images/duck.png';
import reddit from './images/reddit.png';
import globevid from './images/globe.mp4';
import review from './images/newcred2.png';
import test1 from './images/victoria.jpg';
import test2 from './images/peter.jpg';
import test3 from './images/leo.jpg';
import test4 from './images/erica.jpg';
import test5 from './images/maryv.jpg';
import test6 from './images/eugene.jpg';

export default function Backup(props) {
  const ref = React.useRef(null);

  const [show, setShow] = useState(false);
  const [showSbs, setShowSbs] = useState(false);
  const [demo, setDemo] = useState({});
  const [demo1, setDemo1] = useState(false);
  const [demo2, setDemo2] = useState(false);
  const [demo3, setDemo3] = useState(false);
  const [updater, setUpdater] = useState(false);
  const [storefrontYes, setStorefrontYes] = useState(false);
  const [storefrontNo, setStorefrontNo] = useState(false);

  const handleStorefrontYes = () => {setStorefrontYes(true); setStorefrontNo(false);};
  const handleStorefrontNo = () => {setStorefrontYes(false); setStorefrontNo(true);};

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSbsSub = () => {
    let storefront = '';
    if(storefrontYes && !storefrontNo){
      storefront = 'Yes';
    } else if(storefrontNo && !storefrontYes){
      storefront = 'No';
    }
    const sbspkg = {
      sbsemployees: demo.sbsemployees,
      sbsrevenue: demo.sbsrevenue,
      sbsstorefront: storefront,
      sbsbiztime: demo.sbsbiztime
    }
    console.log(sbspkg.sbsrevenue, sbspkg.sbsstorefront);

    fetch('/sbssubmit', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(sbspkg)
    })
    .then(
      console.log("SBS Success"),
      setShowSbs(false)
    )
  }

  const handleChange = (e) => {
    let changer = demo;
    changer[e.target.name] = e.target.value;
    setDemo(changer);
    setUpdater(!updater);
  }

  const handlePromo = () => {if(demo.democheck2 === props.dCode){setDemo2(true)}};
  const handleRoi = () => {setDemo3(true)};
  const handleCred = () => {setDemo1(true); setTimeout(() => ref.current.scrollIntoView({behavior:'smooth'}),500);}
  const handleSbs = () => setShowSbs(true);
  const handleSbsClose = () => setShowSbs(false);

  return(
    <>
    <img src={phone} alt="Boost your business website with The First Page Group!" id="contentimg" width="200" height="200" onClick={handleShow} />

    <Modal size='xl' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title ref={ref}>See How The First Page Group Can Help You Generate Sellers and Buyers Organically</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        {demo1 === false &&
          <>
          <div className="container mheadline">
            <h3>We Have Helped Many Businesses Throughout the World Become Found Online</h3>
          </div>
          <div className="container imgrack">
              <div className="row">
                <div className="col-2">
                  <img src={google} alt="Google" />
                </div>
                <div className="col-2">
                  <img src={yahoo} alt="Yahoo" />
                </div>
                <div className="col-2">
                  <img src={bing} alt="Bing" />
                </div>
                <div className="col-2">
                  <img src={duck} alt="DuckDuckGo" />
                </div>
                <div className="col-2">
                  <img src={reddit} alt="Reddit" />
                </div>
                <div className="col-2">
                  <img src={yt} alt="YouTube" />
                </div>
              </div>
          </div>
          <div className="container mheadline">
            <h3>WE CONNECT CLIENTS AROUND THE GLOBE</h3>
          </div>
          <ReactPlayer className="heroVid" url={globevid} playing loop muted width="100%" height="auto" />
          <img className="map" src={impress} alt="Areas the First Page Group serves." />
          
          <Button variant="success" onClick={handleCred} className="w-100">Continue</Button>
          </>
        }
        {demo1 === true && demo2 === false &&
          <>
          <Form.Group controlId="promocode"></Form.Group>
            <div className="container imgrack2 cardrack">
              <div className="row">
                <div className="col-2"></div>
                <div className="col-8 usaf"><img src={review} alt="Google Reviews" className="w-100" /></div>
                <div className="col-2"></div>
              </div>
            </div>
              <div className="container testboxl">
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-2"><img src={test1} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-8">
                    <h3>Veronica G.</h3>
                    <p>As a new business owner it can be difficult to navigate the advertising waters. We didn't know if we should do print or social media or paid ads on google. So glad that the 1st Pg Group call us and offered us this opportunity to become found online with out having to pay per click. We get calls, emails, and inquiries literally all hours of the day and night and business is booming. Thanks</p>
                  </div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-8">
                    <h3>Tim B.</h3>
                    <p>My Solar panel installations have been up by 60% this month! Has been a great experience working with everyone at first page, staff has been very helpful, glad to be on board business is booming!</p>
                  </div>
                  <div className="col-2"><img src={test2} className="rounded-circle w-100" alt="Review Poster" /></div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-2"><img src={test3} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-8">
                    <h3>Tommy D.</h3>
                    <p>So glad I decided to work with first page group, I was struggling to get people to my site that were qualified and ready and able to purchase. There customer service has been second to none, and you can call and speak with your actual rep that's in the U.S. Second month in a business is booming!</p>
                  </div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-8">
                    <h3>Bonnie P.</h3>
                    <p>First impression was the consultant seemed a little pushy which is why I only leave 4 stars but I'm 2 months in and have gotten several new accounts so I'm glad I decided to work with them.</p>
                  </div>
                  <div className="col-2"><img src={test4} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-2"><img src={test5} className="rounded-circle w-100" alt="Review Poster" /></div>
                  <div className="col-8">
                    <h3>Mary V.</h3>
                    <p>Love the service, I was working from home part-time and wasn't able to get my business off the ground until they helped me bring in enough buyers to now work from home full time I have more time for my family now. Such a great service.</p>
                  </div>
                  <div className="col-1"></div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-1"></div>
                  <div className="col-8">
                    <h3>Bob G.</h3>
                    <p>I have always been hesitant to advertise but the group has been an excellent decision for my Insurance agency I will recommend this to any insurance agent. Thanks for the stellar website and service!</p>
                  </div>
                  <div className="col-2"><img src={test6} className="rounded-circle w-100 h-100" alt="Review Poster" /></div>
                  <div className="col-1"></div>
                </div>
              </div>
            <Button variant="info" onClick={handleSbs} className="w-100">See If You Qualify for Our Small Business Stimulus!</Button>
          <Modal show={showSbs} onHide={handleSbsClose}>
            <Modal.Header closeButton>
              <Modal.Title>See If You Qualify for Our Small Business Stimulus!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="sbsform">
                  <Form.Label>Current Number of Employees:</Form.Label>
                  <Form.Control as="select" name="sbsemployees" onChange={handleChange}>
                    <option>Select One...</option>
                    <option>0 - 10</option>
                    <option>11 - 30</option>
                    <option>31+</option>
                  </Form.Control>
                  <hr />
                  <Form.Label>Revenue Over Last 6 Months:</Form.Label>
                  <Form.Control as="select" name="sbsrevenue" onChange={handleChange}>
                    <option>Select One...</option>
                    <option>Less Than $100,000</option>
                    <option>More Than $100,000</option>
                  </Form.Control>
                  <hr />
                  <Form.Label>Do You Have a Storefront?</Form.Label>
                  <Form.Check type="radio" onChange={handleStorefrontYes} label="Yes" />
                  <Form.Check type="radio" onChange={handleStorefrontNo} label="No" />
                  <hr />
                  <Form.Label>How Long Have You Been in Business?</Form.Label>
                  <Form.Control as="select" name="sbsbiztime" onChange={handleChange}>
                    <option>Select One...</option>
                    <option>Less Than 12 Months</option>
                    <option>More Than 12 Months</option>
                  </Form.Control>
                  <Button variant="success" onClick={handleSbsSub} className="w-100">Submit</Button>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>
          <Form.Group controlId="promocode">
            <Form.Label>Confirm Your Demo Code to Continue</Form.Label>
            <Form.Control type="text" name="democheck2" onChange={handleChange} />
          </Form.Group>
          
          <Button variant="success" onClick={handlePromo} className="w-100">Submit</Button>
          </>
        }
        {demo2 === true && demo3 === false && demo.democheck2 === props.dCode &&
          <>
          <img className="w-100" src={chart} alt="Home Business Infographic" />
          <div id="tabletitle" className="w-100">
            <h4>We have packages available for all sizes of business!</h4>
          </div>
          <Table striped bordered hover className="pkgtbl">
              <thead>
                <tr>
                  <th className="firstcol">Features</th>
                  <th>Starter</th>
                  <th>Standard</th>
                  <th>Business Builder</th>
                  <th>Elite</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="firstcol">Virtual Platform</td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
                <tr>
                  <td className="firstcol">Search Terms with Guaranteed 1st Page Integration</td>
                  <td><span className="pkgnum">2</span></td>
                  <td><span className="pkgnum">4</span></td>
                  <td><span className="pkgnum">8</span></td>
                  <td><span className="pkgnum">16</span></td>
                </tr>
                <tr>
                  <td className="firstcol">Virtual Sales Funnel Design</td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
                <tr>
                  <td className="firstcol">Social Media Integration</td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
                <tr>
                  <td className="firstcol">QR Code Integration</td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
                <tr>
                  <td className="firstcol">Conversion Consulting</td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
                <tr>
                  <td className="firstcol">YouTube Integration</td>
                  <td><img src={no} className="pkgimg" /></td>
                  <td><img src={no} className="pkgimg" /></td>
                  <td><img src={no} className="pkgimg" /></td>
                  <td><img src={yes} className="pkgimg" /></td>
                </tr>
              </tbody>
            </Table>
            <div className="container pkgmobile">
              <div className="row">
                <div className="col-12">
                  <ul>
                    <li className="pkghead">Starter</li>
                    <li>Virtual Platform</li>
                    <li>2 Search Terms with Guaranteed 1st Page Integration</li>
                    <li>Virtual Sales Funnel Design</li>
                    <li>QR Code Integration</li>
                    <li>Conversion Consulting</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ul>
                    <li className="pkghead">Standard</li>
                    <li>Virtual Platform</li>
                    <li>4 Search Terms with Guaranteed 1st Page Integration</li>
                    <li>Virtual Sales Funnel Design</li>
                    <li>Social Media Integration</li>
                    <li>QR Code Integration</li>
                    <li>Conversion Consulting</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ul>
                    <li className="pkghead">Business Builder</li>
                    <li>Virtual Platform</li>
                    <li>8 Search Terms with Guaranteed 1st Page Integration</li>
                    <li>Virtual Sales Funnel Design</li>
                    <li>Social Media Integration</li>
                    <li>Conversion Consulting</li>
                    <li>QR Code Integration</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ul>
                    <li className="pkghead">Elite</li>
                    <li>Virtual Platform</li>
                    <li>16 Search Terms with Guaranteed 1st Page Integration</li>
                    <li>Virtual Sales Funnel Design</li>
                    <li>Social Media Integration</li>
                    <li>YouTube Integration</li>
                    <li>Social Media Training</li>
                    <li>Conversion Consulting</li>
                    <li>QR Code Integration</li>
                  </ul>
                </div>
              </div>
            </div>
          <Button variant="secondary" className="w-100" onClick={handleRoi}>See Your Profit Projection</Button>
          </>
        }
        {demo3 === true &&
          <>
          <h6>The criteria for this illustrated projection are as follows: Based on the average profits and standard conversion rates for your industry, we estimate a profit projection as follows:</h6>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Package</th>
                <th>Low Estimate</th>
                <th>High Estimate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Standard (4 Term Virtual Platform)</td>
                <td>$13,424</td>
                <td>$26,848</td>
              </tr>
              <tr>
                <td>Business Builder (8 Term Virtual Platform)</td>
                <td>$26,848</td>
                <td>$53,696</td>
              </tr>
              <tr>
                <td>Elite (16 Term Virtual Platform)</td>
                <td>$53,696</td>
                <td>$107,392</td>
              </tr>
            </tbody>
          </Table>
          <PayModal self="guide" />
          </>
        }
        </Form>
      </Modal.Body>
    </Modal>
    </>
  )
}
