import React, {Component} from 'react';
import {Button, Modal, Form, Alert} from 'react-bootstrap';
import ACTerms from './AdminClientTerms';

class AdminDetails extends Component{
  constructor(props, context) {
      super(props, context);

      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleNotes = this.handleNotes.bind(this);
      this.handleSEO = this.handleSEO.bind(this);
      this.handleAlter = this.handleAlter.bind(this);
      this.getMR = this.getMR.bind(this);

      this.state = {
        show:false,
        oldNotes: this.props.customer.allnotes,
        seourl: this.props.customer.seoSite,
        newNote: '',
        mrshow: false,
        mr: {}
      }
    }

    componentDidUpdate(props){
      if (props.customer.bustype !== undefined && this.state.mrshow === false){
        this.getMR();
        this.setState({mrshow: true});
      }
    }

    handleShow() {
      this.setState({show:true});
    }

    handleClose() {
      this.setState({show:false});
    }

    handleNotes(e){
      this.setState({newNote: e.target.value});
    }

    handleSEO(e){
      this.setState({seourl: e.target.value});
    }

    handleAlter(){
      let date = new Date();
      let datetime = date.toString();
      let fullNote = this.state.oldNotes + " " + datetime.slice(0, 24) + ": " + this.state.newNote;
      const pkg = {
        notes: fullNote,
        seoSite: this.state.seourl,
        username: this.props.customer.username,
        step: this.props.customer.step,
        lastupdate: datetime,
        newnote: this.state.newNote
      }
      fetch('/clientUpdate', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
      })
    }

    getMR(){
      let j = 0;
      console.log(this.props.customer.bustype);
      if(this.props.customer.bustype === 'Home Business'){
        fetch('/getHBMR')
          .then(res => res.json()
            .then(body => {
              if (res.ok)
                for (j in body){
                  if (body[j].emailaddress === this.props.customer.emailaddress){
                    console.log(body[j]);
                    this.setState({mr: body[j]});
                  }
                }
              // throw new Error(body.err)
            })
          )
      } else if (this.props.customer.bustype === 'Real Estate'){
        fetch('/getREMR')
          .then(res => res.json()
            .then(body => {
              if (res.ok)
                for (j in body){
                  if (body[j].emailaddress === this.props.customer.emailaddress){
                    this.setState({mr: body[j]});
                  }
                }
              // throw new Error(body.err)
            })
          )
      }
      setTimeout(console.log(this.state.mr), 5000);
    }

    render(){
      return(
        <>
        <Button variant="dark" onClick={this.handleShow}>Details</Button>
        <Modal size="xl" show={this.state.show} onHide={this.handleClose} backdrop={false}>
          <Modal.Header closeButton>
            <h3>Full Details for {this.props.customer.fullname}</h3>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <h5>Latest Update:</h5>
              <p>{this.props.customer.newnote}</p>
              <p><strong>Last Updated On:</strong> {this.props.customer.lastupdate}</p>
              <h5>All Notes:</h5>
              <p>{this.state.oldNotes}</p>
              <hr />
              <Form.Label>Add Notes:</Form.Label>
              <Form.Control as="textarea" onChange={this.handleNotes} />
              <Form.Label>SEO Build URL:</Form.Label>
              <Form.Control type="text" onChange={this.handleSEO} value={this.state.seourl} />
              <Button variant="primary" onClick={this.handleAlter}>Update Notes/URL</Button>
            </Form>
            <ul>
              <li className="listHeader">Contact Info</li>
              <li>Company Contact: {this.props.customer.contactname}</li>
              <li>Phone: {this.props.customer.phone}</li>
              <li>Email: {this.props.customer.emailaddress}</li>
              <li>Original Site: <a href={this.props.customer.site} target="_blank" rel="noopener noreferrer">{this.props.customer.site}</a></li>
              <li>SEO Site: <a href={this.props.customer.seoSite} target="_blank" rel="noopener noreferrer">{this.props.customer.seoSite}</a></li>
              <li><strong>Address:</strong></li>
              <li>{this.props.customer.address} {this.props.customer.city}, {this.props.customer.state} {this.props.customer.zip}</li>
              <li className="listHeader">Other</li>
              <ACTerms customer={this.props.customer} />
              <li>Customer Since: {this.props.customer.date}</li>
              <li>Username: {this.props.customer.username}</li>
              <li>Price: {this.props.customer.discountprice}</li>
              <li>Promo Code: {this.props.customer.ordercode}</li>
            </ul>
            {this.state.mrshow && this.props.customer.bustype === 'Home Business' && this.state.mr.emailaddress !== undefined &&
              <>
              <ul>
                <li className="listHeader">Marketing Review Responses</li>
                <li>Products: {this.state.mr.product}</li>
                <li>Demographics: {this.state.mr.demos}</li>
                <li>Areas: {this.state.mr.areas}</li>
                <li>Product Price: {this.state.mr.price}</li>
                <li>Closer Type: {this.state.mr.close}</li>
                <li>Needs Training: {this.state.mr.train}</li>
                <li>Has a Script: {this.state.mr.script}</li>
                <li>How Do Sales Work: {this.state.mr.how}</li>
                <li>How Do You Get Leads: {this.state.mr.inquire}</li>
                <li>Sales Process: {this.state.mr.process}</li>
                <li>Website URLs: {this.state.mr.url}</li>
                <li>Contact Numbers: {this.state.mr.numbers}</li>
                <li>Bio: {this.state.mr.bio}</li>
                <li>Socials: {this.state.mr.socials}</li>
                <li>Business Type: {this.state.mr.bustype}</li>
                <li>Business Other Type: {this.state.mr.othertype}</li>
                <li>Verbiage: {this.state.mr.verb}</li>
                <li>Back Office: {this.state.mr.back}</li>
                <li>Logo: {this.state.mr.logo}</li>
                <li>URL Suggestions: {this.state.mr.suggest}</li>
                <li>Goals: {this.state.mr.goal}</li>
                <li>Keyterm Selection: {this.state.mr.kt}</li>
              </ul>
              </>
            }
            {this.state.mrshow && this.props.customer.bustype === 'Real Estate' && this.state.mr.emailaddress !== undefined &&
              <>
              <ul>
                <li className="listHeader">Marketing Review Responses</li>
                <li>Agent/Business Type: {this.state.mr.bustype}</li>
                <li>Transaction Types: {this.state.mr.trans}</li>
                <li>Licenses/Certifications: {this.state.mr.certs}</li>
                <li>Years as Realtor: {this.state.mr.years}</li>
                <li>Designations: {this.state.mr.desig}</li>
                <li>Broker: {this.state.mr.broker}</li>
                <li>Buyers or Sellers: {this.state.mr.buysell}</li>
                <li>Buyer/Seller Ratio: {this.state.mr.ratio}</li>
                <li>Areas to Target: {this.state.mr.areas}</li>
                <li>Cities to Target: {this.state.mr.cities}</li>
                <li>Neighborhoods to Target: {this.state.mr.neighborhood}</li>
                <li>Specific Areas to Target: {this.state.mr.specifics}</li>
                <li>Price Range: {this.state.mr.range}</li>
                <li>Preferred Client Type: {this.state.mr.client}</li>
                <li>Other Client Type: {this.state.mr.otherclient}</li>
                <li>Socials: {this.state.mr.socials}</li>
                <li>Update Info: {this.state.mr.updates}</li>
                <li>Updated Phone: {this.state.mr.phone}</li>
                <li>Updated URL: {this.state.mr.site}</li>
                <li>Updated Email: {this.state.mr.email}</li>
                <li>Updated Address: {this.state.mr.address}</li>
                <li>Bio: {this.state.mr.bio}</li>
                <li>Awards: {this.state.mr.awards}</li>
                <li>Keyterm Selection: {this.state.mr.kt}</li>
              </ul>
              </>
            }
          </Modal.Body>
        </Modal>
        </>
      )
    }
  }
export default AdminDetails;
