import React, {Component} from 'react';
import {Button, Modal, ListGroup, ListGroupItem, Tabs, Tab, Form, Alert, Card, Badge} from 'react-bootstrap';
import LeadModal from './LeadModal';
import AdminDetails from './AdminDetails';
import TermDisplay from './TermDisplay';
import MReview from './MarketingReview';

class AdminModal extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handlePassChange = this.handlePassChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handlePackage = this.handlePackage.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeytermChange = this.handleKeytermChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);

        this.state = {
            show: false,
            package: '',
            fullname: '',
            email: '',
            username: '',
            pass: '',
            submitted: false,
            numTerms: 0,
            terms: '',
            locations: ''
        };
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleNameChange(event) {
      this.setState({fullname: event.target.value});
    }

    handleEmailChange(event) {
      this.setState({email: event.target.value});
    }

    handlePackage(event) {
      this.setState({package: event.target.value});
    }

    handleUserChange(event){
      this.setState({username: event.target.value});
    }

    handlePassChange(event){
      this.setState({pass: event.target.value});
    }

    handleKeytermChange(event){
      this.setState({terms: event.target.value});
    }

    handleLocationChange(event){
      this.setState({locations: event.target.value});
    }

    handleSubmit(){
      let termlist = this.state.terms.split(',');
      let loclist = this.state.locations.split(',');
      const pkg = {
        fullname: this.state.fullname,
        email: this.state.email,
        package: this.state.package,
        username: this.state.username,
        password: this.state.pass,
        terms: termlist,
        locations: loclist
      }
      fetch('/adminUpdate', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
      })
      .then(
        this.setState({submitted:true})
      )
    }

    handleDemoCode(){
      let rng = Math.floor(Math.random() * (9999 - 1000)) + 1000;
      let dCode = rng.toString();
      fetch('/newCode', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({code: dCode})
      })
      alert("Your demo code: " + dCode);
    }

    render(){
      return (
        <>
        <Button variant="danger" onClick={this.handleShow}>Admin Area</Button>
        <Modal size="xl" show={this.state.show} onHide={this.handleClose} backdrop={false}>
          <Modal.Header closeButton>
            <h3>Admin Backend</h3>
            <Button variant="secondary" onClick={this.handleDemoCode}>Create Demo Code</Button>
          </Modal.Header>
          {this.props.isAdmin === false &&
            <Modal.Body>
              <h4>You must be logged in to view this content. Please close this window and login via the login button in the top right.</h4>
            </Modal.Body>
          }
          {this.props.isAdmin &&
            <Modal.Body>
              <Tabs defaultActiveKey="customers" id="admin-tabs">
                <Tab eventKey="customers" title="Customer Information">
                  <div className="container">
                    <div className="row">
                      <CustomerRender />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="new" title="New Customer">
                  <div className="container">
                    <Alert variant="danger">This tool can only be used to create a NEW entry, not update an existing one!</Alert>
                    <Form>
                      <Form.Group controlId="new-customer-info">
                        <Form.Label>Customer Full Name</Form.Label>
                        <Form.Control type="text" placeholder="Must Match Customer's Name Exactly" onChange={this.handleNameChange} />
                        <Form.Label>Customer Email Address</Form.Label>
                        <Form.Control type="email" placeholder="Must Match Customer's Email Exactly" onChange={this.handleEmailChange}/>
                        <Form.Label>Customer Username</Form.Label>
                        <Form.Control type="text" placeholder="Must Match Exactly. This is the customer's email prefix (before the @)." onChange={this.handleUserChange} />
                        <Form.Label>New Customer Password</Form.Label>
                        <Form.Control type="text" placeholder="This is now the customer's last name (all lowercase) plus the last 4 digits of their phone number." onChange={this.handlePassChange} />
                      </Form.Group>
                      <Form.Group controlId="new-customer-keyterms">
                        <Form.Label>Select Package</Form.Label>
                        <Form.Control as="select" onChange={this.handlePackage}>
                          <option>Select a Package...</option>
                          <option>Starter</option>
                          <option>Standard</option>
                          <option>Business Builder</option>
                          <option>Elite</option>
                        </Form.Control>
                        <Form.Label>Keyterm List, Separated by Commas, No Spaces Around Commas!</Form.Label>
                        <Form.Control as="textarea" rows="5" placeholder="Only works if it looks like this: 'This is a Keyterm,Another Keyterm,More Terms Here,etc...'" onChange={this.handleKeytermChange} />
                        <Form.Label>Locations List, Separated by Commas, No Spaces Around Commas!</Form.Label>
                        <Form.Control as="textarea" rows="5" placeholder="Only works if it looks like this: 'New York,Chicago,Dallas,etc...'" onChange={this.handleLocationChange} />
                      </Form.Group>
                      {this.state.submitted === false &&
                        <Button variant="primary" onClick={this.handleSubmit}>Submit</Button>
                      }
                      {this.state.submitted &&
                        <Button variant="success" onClick={this.handleClose}>Close</Button>
                      }
                    </Form>
                  </div>
                </Tab>
                <Tab eventKey="leads" title="Lead Management">
                  <Alert variant="warning">Changes from other users in the "Update Lead" Menu won't display here until you refresh the page or close this window and reopen it.</Alert>
                  <div className="container">
                    <div className="row">
                      <LeadRender />
                    </div>
                  </div>
                </Tab>

              </Tabs>
            </Modal.Body>
          }
        </Modal>
        </>
      );
    }
  }

class CustomerRender extends Component {
  constructor(props, context) {
      super(props, context);

      this.customerData = this.customerData.bind(this);
      this.filterCustomers = this.filterCustomers.bind(this);

      this.state = {
          customers: [],
          filteredCustomers: []
      };
  }

  componentWillMount(){
    this.customerData();
  }

  customerData() {
    fetch('/customers')
      .then(res => res.json()
        .then(body => {
          if (res.ok)
            this.setState({customers: body, filteredCustomers: body});
          // throw new Error(body.err)
        })
      )
  }

  filterCustomers(e){
    let toFilter = this.state.customers;
    let term = e.target.value;
    let x = 0;
    for (x in toFilter){
      toFilter[x].term = term
    }
    function isInside(item, term){
      if (item !== null && item !== undefined){
        if (item.includes(term)){
          return item
        }
      }
    }

    function seeker(entry){
      if (isInside(entry.fullname, entry.term) || isInside(entry.company, entry.term) || isInside(entry.site, entry.term) || isInside(entry.seoSite, entry.term) || isInside(entry.package, entry.term) || isInside(entry.consultant, entry.term) || isInside(entry.step, entry.term) || isInside(entry.industry, entry.term)) {
        return true
      }
      return false
    }
    let results = [];
    if (e.target.value !== ''){
      results = toFilter.filter(seeker);
    }
    this.setState({filteredCustomers: results});
    if (e.target.value === ''){
      this.setState({filteredCustomers: this.state.customers})
    }

  }

  render(){
    let rows = [];
    let i = 0;
    for ( i in this.state.filteredCustomers ){
      if (this.state.filteredCustomers[i].company !== '' && this.state.filteredCustomers[i].company !== null && this.state.filteredCustomers[i].step !== 'Expired'){
        rows.push(
          <>
          <Card style={{width:'16rem'}} className={this.state.filteredCustomers[i].step}>
            <Card.Body>
              <Card.Title>{this.state.filteredCustomers[i].fullname}</Card.Title>
            </Card.Body>
            <ListGroup className="customer-detail">
              <ListGroupItem>Company: {this.state.filteredCustomers[i].company}</ListGroupItem>
              <ListGroupItem>Industry: {this.state.filteredCustomers[i].industry}</ListGroupItem>
              <ListGroupItem>Consultant: {this.state.filteredCustomers[i].consultant}</ListGroupItem>
              <ListGroupItem>Next Step: {this.state.filteredCustomers[i].step}</ListGroupItem>
            </ListGroup>
            <Card.Body>
              <AdminDetails customer={this.state.filteredCustomers[i]} />
              <MReview customer={this.state.filteredCustomers[i]} />
            </Card.Body>
          </Card>
          </>
        )
      }
    }
    return (
      <>
      <div style={{width:'100%'}}>
      <Form>
        <Form.Label>Filter Results:</Form.Label>
        <Form.Control type="text" onChange={this.filterCustomers} />
      </Form>
      </div>
      {rows}
      </>
    );
  }
}

class LeadRender extends Component {
  constructor(props, context) {
      super(props, context);

      this.leadData = this.leadData.bind(this);
      this.leadUpdate = this.leadUpdate.bind(this);
      this.filterLeads = this.filterLeads.bind(this);

      this.state = {
          leads: [],
          filteredLeads: []
      };
  }

  componentWillMount(){
    this.leadData();
  }

  leadData() {
    fetch('/leads')
      .then(res => res.json()
        .then(body => {
          if (res.ok)
            this.setState({leads: body, filteredLeads: body});
          // throw new Error(body.err)
        })
      )
  }

  leadUpdate(leadname, consultant, dispo, action, note, j){
    for(j in this.state.leads){
      if(this.state.leads[j].name === leadname){
        let updateLeads = this.state.leads;
        updateLeads[j].consultant = consultant;
        updateLeads[j].disposition = dispo;
        updateLeads[j].action = action;
        updateLeads[j].notes = note;
        this.setState({leads: updateLeads});
      }
    }
  }

  filterLeads(e){
    let toFilter = this.state.leads;
    let term = e.target.value;
    let x = 0;
    for (x in toFilter){
      toFilter[x].term = term
    }
    function isInside(item, term){
      if (item !== null && item !== undefined){
        if (item.includes(term)){
          return item
        }
      }
    }

    function seeker(entry){
      if (isInside(entry.name, entry.term) || isInside(entry.company, entry.term) || isInside(entry.consultant, entry.term) || isInside(entry.disposition, entry.term) || isInside(entry.action, entry.term)) {
        return true
      }
      return false
    }
    let results = [];
    if (e.target.value !== ''){
      results = toFilter.filter(seeker);
    }
    this.setState({filteredLeads: results});
    if (e.target.value === ''){
      this.setState({filteredLeads: this.state.leads})
    }

  }

  render(){
    let leadRows = [];
    let j = 0;
    for (j in this.state.filteredLeads){
      let telly = "tel:" + this.state.filteredLeads[j].phone;
      if(this.state.filteredLeads[j].phone !== "undefined"){
      leadRows.push(
        <>
        <Card style={{ width: '16rem' }} className={this.state.filteredLeads[j].disposition}>
          <Card.Body>
            <Card.Title>{this.state.filteredLeads[j].name}</Card.Title>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem><a href={telly}>{this.state.filteredLeads[j].phone}</a></ListGroupItem>
            <ListGroupItem className="company">{this.state.filteredLeads[j].company}</ListGroupItem>
            <ListGroupItem>Assigned To: {this.state.filteredLeads[j].consultant}</ListGroupItem>
            <ListGroupItem>Disposition: {this.state.filteredLeads[j].disposition}</ListGroupItem>
            <ListGroupItem>Next Action: {this.state.filteredLeads[j].action}</ListGroupItem>
          </ListGroup>
          <Card.Body>
            <LeadModal lead={this.state.filteredLeads[j]} leadUpdate={this.leadUpdate} />
          </Card.Body>
        </Card>
        </>
      )}
    }
    return(
      <>
      <div style={{width:'100%'}}>
      <Form>
        <Form.Label>Filter Results:</Form.Label>
        <Form.Control type="text" onChange={this.filterLeads} />
      </Form>
      </div>
      {leadRows}
      </>
    );
  }
}

class TermList extends Component {
  constructor(props, context) {
      super(props, context);

      this.filterTerms = this.filterTerms.bind(this);
      this.termData = this.termData.bind(this);

      this.state = {
          keyterms: [],
          filteredTerms: []
      };
  }

  componentWillMount(){
    this.termData();
  }

  termData() {
    fetch('/termList')
      .then(res => res.json()
        .then(body => {
          if (res.ok)
            this.setState({keyterms: body, filteredTerms: body});
          // throw new Error(body.err)
        })
      )
  }

  filterTerms(e){
    let toFilter = this.state.keyterms;
    let term = e.target.value;
    let x = 0;
    for (x in toFilter){
      toFilter[x].term = term
    }
    function isInside(item, term){
      if (item !== null && item !== undefined){
        if (item.includes(term)){
          return item
        }
      }
    }

    function seeker(entry){
      if (isInside(entry.phrase, entry.term) || isInside(entry.source, entry.term) || isInside(entry.usage, entry.term)) {
        return true
      }
      return false
    }
    let results = [];
    if (e.target.value !== ''){
      results = toFilter.filter(seeker);
    }
    this.setState({filteredTerms: results});
    if (e.target.value === ''){
      this.setState({filteredTerms: this.state.keyterms})
    }
  }

  render(){
    let termRows=[];
    let k=0;
    for (k in this.state.filteredTerms){
      termRows.push(
        <>
        <li>
          {this.state.filteredTerms[k].phrase}
          {this.state.filteredTerms[k].source === 'Ours' && <Badge variant='success'>1stPage</Badge>}
          {this.state.filteredTerms[k].source === 'Theirs' && <Badge variant='warning'>Other</Badge>}
          {this.state.filteredTerms[k].usage === 'Yes' && <Badge variant='danger'>In Use</Badge>}
          {this.state.filteredTerms[k].usage === 'No' && <Badge variant='info'>Unused</Badge>}
          <TermDisplay term={this.state.filteredTerms[k].phrase} text={this.state.filteredTerms[k].text} source={this.state.filteredTerms[k].source} usage={this.state.filteredTerms[k].usage} />
        </li>
        <hr />
        </>
      )
    }
    return(
      <>
      <div style={{width:'100%'}}>
        <Form>
          <Form.Label>Filter Results: (Type "Ours" for 1stPage Terms, "Theirs" for Other Terms, "Yes" for Terms in Use, and "No" for Unused Terms)</Form.Label>
          <Form.Control type="text" onChange={this.filterTerms} />
        </Form>
        <ul id="termTitles">
          {termRows}
        </ul>
      </div>
      </>
    )
  }
}

export default AdminModal;
