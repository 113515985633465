import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap';

export default function SelectSuccess(){
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return(
    <>
    <Button variant="success" type="submit" onClick={handleShow}>SUCCESS!</Button>
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Phrase Selection Complete!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Thank you for selecting your phrases. Get ready to be found on the first pages of Google, Yahoo, and Bing!</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}
